import { Routes, Route } from 'react-router-dom'
import './App.css'
import Auth from './pages/auth'
import SingleExamPage from './pages/single_exam_page'
import ProtectedRoutes from './components/protected routes/ProtectedRoutes'
import { Logout } from './pages/logout'
import NotFound from './pages/NotFound'
import { AccessControl } from './components/auth/accessControl'
import ResetAuth from './pages/resetAuth'
import ForgotPasssword from './pages/forgotPassword'
import UserPage from './pages/userPage'
import InquiryPage from './pages/inquiry'
import CataloguePage from './pages/catalogue'
import OrderPage from './pages/order'
import CustomerPage from './pages/customer'
import InvoicePage from './pages/invoice'
import KycPage from './pages/kycPage'
import DiamondsPage from './pages/diamonds'
import HoldPage from './pages/holden'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="logout" element={<Logout />} />
        <Route
          path="users"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <UserPage />
            </AccessControl>
          }
        />
        <Route
          path="inquiry"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <InquiryPage />
            </AccessControl>
          }
        />
        <Route
          path="catalogue"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <CataloguePage />
            </AccessControl>
          }
        />
        <Route
          path="order"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <OrderPage />
            </AccessControl>
          }
        />
        <Route
          path="customer"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <CustomerPage />
            </AccessControl>
          }
        />
        <Route
          path="invoice"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <InvoicePage />
            </AccessControl>
          }
        />
        <Route
          path="kyc"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <KycPage />
            </AccessControl>
          }
        />
        <Route
          path='diamonds'
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <DiamondsPage />
            </AccessControl>
          } />
        <Route
          path='hold-diamonds'
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <HoldPage />
            </AccessControl>
          } />
      </Route>
      <Route path="resetpassword" element={<ResetAuth />} />
      <Route path="*" element={<NotFound />} />
      <Route path="reset-password/:id" element={<ForgotPasssword />} />
    </Routes>
  )
}

export default App
