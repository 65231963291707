import { useState, useEffect, useCallback, useMemo } from 'react'
import Button from '../components/button'
import app_api from '../config/api'
import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    PhotoIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import Modal from '../components/modals'
import { Formik } from 'formik'
import Table from '../components/tables/table'
import { diamonds_columns } from '../components/tables/tableheader'
import HalfGeneralSlideover from '../components/half-general-slideover'
import CurrencyInput from 'react-currency-input-field'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Select from 'react-select'
import * as excelJs from "exceljs";

const initialModalState = {
    type: '',
    state: false,
    index: null,
    edit_id: '',
    data: {
        fileName: ''
    },
}

const initialCatalogueState = {
    type: '',
    modalState: false,
    index: null,
    id: '',
    data: {
        packetNo: '',
        reportNo: '',
        lab: '',
        location: '',
        dna: '',
        video: '',
        nakedEye: '',
        shape: '',
        carat: '',
        color: '',
        clarity: '',
        shade: '',
        rap: '',
        discount: '',
        price: '',
        cut: '',
        polish: '',
        symmetry: '',
        fluorescence: '',
        brilliancy: '',
        length: '',
        width: '',
        depth: '',
        tableper: '',
        depthper: '',
        ratio: '',
        centerNatts: '',
        sideNatts: '',
        centerWhite: '',
        sideWhite: '',
        tableOpen: '',
        crownOpen: '',
        pavillionOpen: '',
        EyeClean: '',
        crownAngle: '',
        pavillionAngle: '',
        crownHeight: '',
        pavillionHeight: '',
        girdle: '',
        girdlePer: '',
        culet: '',
        laserIns: '',
        heartArrow: '',
        reportComment: '',
        lower: '',
        star: '',
        pairPocketNo: '',
        dor: '',
        seal: '',
        cmNo: '',
        fpc: '',
        xray: '',
        typeTwoCarat: '',
        svs: '',
        tracer: '',
        diavik: '',
        quantity: '',
        keyToSymbol: '',
        status: '',
    },
}

const BooleanOptions=[
    {label:'Yes',value:'Yes'},
    {label:'No', value:'No'}
]

const DiamondsPage = () => {
    const [modal, setModal] = useState(initialModalState)
    const [modalCatalogue, setModalCatalogue] = useState(initialCatalogueState)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const [searchFilter, setSearchFilter] = useState('')

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [TabData, setTabData] = useState([])

    const goPrev = () => {
        if (page > 0) setPage((prev) => --prev)
    }

    const goNext = () => {
        if (page < totalPages - 1) setPage((prev) => ++prev)
    }

    const cleanModalData = () => {
        setModal(initialModalState)
    }

    const getCustomerData = () => {
        let url = `/search-diamonds?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
        app_api.get(url)
            .then((res) => (res.data))
            .then((res) => {
                const data = res.data
                setTabData(data.data)
                setCount(data.count)
                setTotalPages(data.count / limit)
                setLoading(false)
                cleanModalData()
            }).catch((err) => {
                setError(err)
                setLoading(true)
            })
    }

    const cleanCatologueData = () => {
        setModalCatalogue(initialCatalogueState)
    }

    useEffect(() => {
        getCustomerData()
    }, [page, limit, searchFilter])

    const onEditOpen = (id, index) => {
        setModalCatalogue((prev) => ({
            ...prev,
            type: 'edit',
            id: id,
            index: index,
            modalState: true,
            data: TabData[index],
        }))
    }

    const onDelete = (id, index) => {
        setModalCatalogue((prev) => ({
            ...prev,
            id: id,
            index: index,
        }))
        setConfirmationDialog(true)
    }

    const onDeleteCategory = () => {
        const { id } = modalCatalogue
        app_api
            .delete(`/search-diamonds/${id}`)
            .then((res) => {
                toast.success('Diamond detail is deleted successfully')
                getCustomerData()
                setConfirmationDialog(false)
            })
            .catch((err) => {
                setError(err.toString())
                toast.error(err.response.data.message)
            })
    }

    const downloadSample = async () =>{
        const wb = new excelJs.Workbook();
        const ws = wb.addWorksheet("Sheet 1");
        ws.addRow([
            "Quantity",
            "Packet No",
            "Report No",
            "Lab",
            "Location",
            "Naked Eye",
            "Shape",
            "Carat",
            "Color",
            "Clarity",
            "Shade",
            "Rap.($)",
            "Disc %",
            "Price Per Carat",
            "Cut",
            "Polish",
            "Symmetry",
            "Flurescence",
            "Brilliancy",
            "Length",
            "Width",
            "Depth",
            "Table %",
            "Depth %",
            "Center Natts",
            "Side Nattes",
            "Center White",
            "Side White",
            "Table Open",
            "Center Open",
            "Pavillian Open",
            "Eye Clean",
            "Pavillion Angle",
            "PAvillion Height",
            "Center Angle",
            "Center Height",
            "Girdle",
            "Girdle %",
            "Culet",
            "Laser Ins.",
            "Heart and Arrow",
            "Report Comments",
            "Key To Symbols",
            "Lower",
            "Star",
            "Pair Pocket Number",
            "DOR",
            "SEAL",
            "CM No",
            "FPC",
            "Xray",
            "Type IICert",
            "SVS (GIA)",
            "Tracer",
            "DIAVIK",
            "Status"
        ]);
        ws.columns.map((col, index) => (col.width = 18));
        ws.dataValidations.add("C2:C99999", {
          type: "list",
          allowBlank: false,
        //   formulae: [`"${Grade.join(",")}"`],
        });
        ws.dataValidations.add("D2:D99999", {
          type: "list",
          allowBlank: false,
        //   formulae: [`"${Division.join(",")}"`],
        });
        ws.dataValidations.add("G2:G99999", {
          type: 'date',
          operator: 'between',
        //   formula1: new Date(1900, 0, 1), // Minimum date value (e.g., January 1, 1900)
        //   formula2: new Date(9999, 11, 31) // Maximum date value (e.g., December 31, 9999)
        });
        const excelBlob = await wb.xlsx.writeBuffer();
        const excelUrl = URL.createObjectURL(
          new Blob([excelBlob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "sample.xlsx";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    }

    const renderUpload = () => {
        const { state, data } = modal
        return (
            <Formik
                initialValues={{
                    ...data,
                    fileName: '',
                }}
                validationSchema={null}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    const headers = {
                        'Content-Type': 'multipart/form-data',
                    }
                    const formdata = new FormData()
                    formdata?.append('file', values.fileName)
                    app_api
                        .post('search-diamonds/import', formdata, headers)
                        .then((res) => {
                            setModal(initialModalState)
                            getCustomerData()
                            setSubmitting(false)
                        })
                        .catch((err) => {
                            getCustomerData()
                            setError(err)
                            setSubmitting(false)
                        })
                }
                }
            >
                {({
                    handleSubmit,
                    setValues,
                    values,
                    isSubmitting,
                }) => (
                    <Modal
                        title={'Upload Catalogue'}
                        open={state}
                        setOpen={() => {
                            setModal((prev) => ({ ...prev, state: false }))
                            cleanModalData(setValues)
                        }}
                    >
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="mt-4 text-left">
                                <div
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        setValues({
                                            ...values,
                                            fileName: e.dataTransfer.files[0],
                                        });
                                    }}
                                    className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                                >
                                    <div className="text-center">
                                        <PhotoIcon
                                            className="mx-auto h-12 w-12 text-gray-300"
                                            aria-hidden="true"
                                        />
                                        <div className="mt-4 flex text-sm leading-6 text-primary">
                                            <label
                                                htmlFor="fileName"
                                                className="relative flex cursor-pointer rounded-md bg-white font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                                            >
                                                <span>Upload a file</span>
                                                <input
                                                    id="fileName"
                                                    name="fileName"
                                                    type="file"
                                                    accept=".xlsx, .xls"
                                                    className="sr-only"
                                                    onChange={(e) => {
                                                        console.log(e.target.files[0])
                                                        setValues({
                                                            ...values,
                                                            fileName: e.target.files[0],
                                                        });
                                                    }}
                                                />

                                                <p className="pl-1">or drag and drop</p>
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                                            {values.fileName
                                                ? values?.fileName?.name
                                                : "XLXS"}
                                            {values.fileName && (
                                                <XMarkIcon
                                                    className="w-4 text-red-500 cursor-pointer"
                                                    onClick={() => {
                                                        setValues({
                                                            ...values,
                                                            fileName: "",
                                                        });
                                                    }}
                                                />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-4 sm:mt-6">
                                    <Button type="submit" disabled={isSubmitting}>
                                        Upload
                                    </Button>
                                    <p className='flex justify-end' > <span className='cursor-pointer text-[#d6a093] mt-1'
                                    onClick={() => downloadSample()}
                                    >Download Sample</span></p>
                                </div>
                            </div>
                        </form>
                    </Modal>
                )}
            </Formik>
        )
    }

    const renderModal = () => {
        const { type, modalState, data, id } = modalCatalogue
        data.EyeClean = data?.EyeClean ? 'Yes' : 'No'
        data.laserIns = data?.laserIns ? 'Yes' : 'No'
        data.svs = data?.svs ? data?.svs ? 'Yes' : 'No' : null
        data.tracer = data?.tracer ? data?.tracer ? 'Yes' : 'No' : null
        data.diavik = data?.diavik ? data?.diavik ? 'Yes' : 'No' : null
        return (
            <Formik
                initialValues={data}
                validationSchema={null}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true)
                    app_api
                        .patch(`search-diamonds/${id}`, {
                            ...values,
                            EyeClean: values?.EyeClean === 'Yes' ? true : false,
                            laserIns: values?.laserIns === 'Yes' ? true : false,
                            svs: values?.svs === 'Yes' ? true : false,
                            tracer: values?.tracer === 'Yes' ? true : false,
                            diavik: values?.diavik === 'Yes' ? true : false,
                        })
                        .then((res) => {
                            getCustomerData()
                            toast.success('Successfully updated')
                            setSubmitting(false)
                            cleanCatologueData()
                        }).catch((err) => {
                            setSubmitting(false)
                            toast.error('Failed to update catalogue')
                        })
                }}
            >
                {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setValues,
                    setFieldTouched,
                    values,
                    touched,
                    isSubmitting,
                    errors,
                }) => (
                    <HalfGeneralSlideover
                        title={'Catalogue'}
                        open={modalState}
                        setOpen={() => {
                            setModalCatalogue((prev) => ({ ...prev, modalState: false }))
                        }}
                    >
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="text-left mt-4">
                                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 flex flex-wrap mt-1'>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Packet Number</label>
                                        </div>
                                        <input
                                            name="packetNo"
                                            label="packetNo"
                                            value={values.packetNo}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Packet No...'
                                            onFocus={() => setFieldTouched('packetNo', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.packetNo && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.packetNo}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Report Number</label>
                                        </div>
                                        <input
                                            name="reportNo"
                                            label="reportNo"
                                            value={values.reportNo}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter reportNo...'
                                            onFocus={() => setFieldTouched('reportNo', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.reportNo && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.reportNo}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Quantity</label>
                                        </div>
                                        <input
                                            name="quantity"
                                            label="quantity"
                                            value={values.quantity}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Quantity...'
                                            onFocus={() => setFieldTouched('quantity', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.quantity && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.quantity}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Clarity</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="clarity"
                                            type="text"
                                            value={values.clarity}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('clarity', false)}
                                            placeholder="Enter Clarity..."
                                        />
                                        {touched.clarity && (
                                            <p className="text-red-700 error_msg">{errors.clarity}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Color</label>
                                        </div>
                                        <input
                                            name="color"
                                            placeholder='Color'
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.color}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('color', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.color && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.color}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Lab</label>
                                        </div>
                                        <input
                                            name="lab"
                                            label="lab"
                                            value={values.lab}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Lab...'
                                            onFocus={() => setFieldTouched('lab', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.lab && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.lab}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Location</label>
                                        </div>
                                        <input
                                            name="location"
                                            label="location"
                                            value={values.location}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Location...'
                                            onFocus={() => setFieldTouched('location', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.location && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.location}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >DNA</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="dna"
                                            type="text"
                                            value={values.dna}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('dna', false)}
                                            placeholder="Enter DNA..."
                                        />
                                        {touched.dna && (
                                            <p className="text-red-700 error_msg">{errors.dna}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Shape </label>
                                        </div>
                                        <input
                                            name="shape"
                                            placeholder='Shape '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.shape}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('shape', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.shape && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.shape}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Carat</label>
                                        </div>
                                        <input
                                            name="carat"
                                            label="carat"
                                            value={values.carat}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Carat...'
                                            onFocus={() => setFieldTouched('carat', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.carat && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.carat}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Shape</label>
                                        </div>
                                        <input
                                            name="shape"
                                            label="shape"
                                            value={values.shape}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Shape...'
                                            onFocus={() => setFieldTouched('shape', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.shape && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.shape}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >RAP</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="rap"
                                            type="text"
                                            value={values.rap}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('rap', false)}
                                            placeholder="Enter Rap..."
                                        />
                                        {touched.rap && (
                                            <p className="text-red-700 error_msg">{errors.rap}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Discount </label>
                                        </div>
                                        <input
                                            name="discount"
                                            placeholder='Discount '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.discount}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('discount', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.discount && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.discount}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Price per Carat</label>
                                        </div>
                                        <input
                                            name="price"
                                            label="price"
                                            value={values.price}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Price per Carat...'
                                            onFocus={() => setFieldTouched('price', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.price && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.price}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Cut</label>
                                        </div>
                                        <input
                                            name="cut"
                                            label="cut"
                                            value={values.cut}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Cut...'
                                            onFocus={() => setFieldTouched('cut', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.cut && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.cut}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Polish</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="polish"
                                            type="text"
                                            value={values.polish}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('polish', false)}
                                            placeholder="Enter Polish..."
                                        />
                                        {touched.polish && (
                                            <p className="text-red-700 error_msg">{errors.polish}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Symmetry </label>
                                        </div>
                                        <input
                                            name="symmetry"
                                            placeholder='Symmetry '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.symmetry}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('symmetry', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.symmetry && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.symmetry}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Fluorescence</label>
                                        </div>
                                        <input
                                            name="fluorescence"
                                            label="fluorescence"
                                            value={values.fluorescence}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Fluorescence...'
                                            onFocus={() => setFieldTouched('fluorescence', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.fluorescence && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.fluorescence}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Brilliancy</label>
                                        </div>
                                        <input
                                            name="brilliancy"
                                            label="brilliancy"
                                            value={values.brilliancy}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Brilliancy...'
                                            onFocus={() => setFieldTouched('brilliancy', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.brilliancy && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.brilliancy}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Length</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="length"
                                            type="text"
                                            value={values.length}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('length', false)}
                                            placeholder="Enter Length..."
                                        />
                                        {touched.length && (
                                            <p className="text-red-700 error_msg">{errors.length}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Depth </label>
                                        </div>
                                        <input
                                            name="depth"
                                            placeholder='Enter Depth... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.depth}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('depth', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.depth && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.depth}
                                            </p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Table Percentage</label>
                                        </div>
                                        <input
                                            name="tableper"
                                            label="tableper"
                                            value={values.tableper}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Table Percentage...'
                                            onFocus={() => setFieldTouched('tableper', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.tableper && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.tableper}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Depth Percent</label>
                                        </div>
                                        <input
                                            name="depthper"
                                            label="depthper"
                                            value={values.depthper}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Depth Percent...'
                                            onFocus={() => setFieldTouched('depthper', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.depthper && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.depthper}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Center Natts</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="centerNatts"
                                            type="text"
                                            value={values.centerNatts}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('centerNatts', false)}
                                            placeholder="Enter Center Natts..."
                                        />
                                        {touched.centerNatts && (
                                            <p className="text-red-700 error_msg">{errors.centerNatts}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Side Natts </label>
                                        </div>
                                        <input
                                            name="sideNatts"
                                            placeholder='Enter Side Natts... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.sideNatts}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('sideNatts', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.sideNatts && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.sideNatts}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Center White</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="centerWhite"
                                            type="text"
                                            value={values.centerWhite}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('centerWhite', false)}
                                            placeholder="Enter Center White..."
                                        />
                                        {touched.centerWhite && (
                                            <p className="text-red-700 error_msg">{errors.centerWhite}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Side White </label>
                                        </div>
                                        <input
                                            name="sideWhite"
                                            placeholder='Enter Side White... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.sideWhite}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('sideWhite', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.sideWhite && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.sideWhite}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Table Open</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="tableOpen"
                                            type="text"
                                            value={values.tableOpen}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('tableOpen', false)}
                                            placeholder="Enter Table Open..."
                                        />
                                        {touched.tableOpen && (
                                            <p className="text-red-700 error_msg">{errors.tableOpen}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Crown Open</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="crownOpen"
                                            type="text"
                                            value={values.crownOpen}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('crownOpen', false)}
                                            placeholder="Enter Crown Open..."
                                        />
                                        {touched.crownOpen && (
                                            <p className="text-red-700 error_msg">{errors.crownOpen}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Pavillion Open</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="pavillionOpen"
                                            type="text"
                                            value={values.pavillionOpen}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('pavillionOpen', false)}
                                            placeholder="Enter Pavillion Open..."
                                        />
                                        {touched.pavillionOpen && (
                                            <p className="text-red-700 error_msg">{errors.pavillionOpen}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Eye Clean </label>
                                        </div>
                                        <Select
                                            name="EyeClean"
                                            placeholder='Enter Eye Clean... '
                                            options={BooleanOptions?.map((l) => ({
                                                ...l,
                                                label: l.label,
                                                value: l.value,
                                            }))}
                                            value={values.EyeClean ? {
                                                value: BooleanOptions?.find(
                                                    (e) => e.value == values.EyeClean
                                                )?.value,
                                                label: BooleanOptions?.find(
                                                    (e) => e.value == values.EyeClean
                                                )?.label,
                                            } : null
                                            }
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    EyeClean: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('EyeClean', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.EyeClean && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.EyeClean}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Crown Angle</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="crownAngle"
                                            type="text"
                                            value={values.crownAngle}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('crownAngle', false)}
                                            placeholder="Enter Crown Angle..."
                                        />
                                        {touched.crownAngle && (
                                            <p className="text-red-700 error_msg">{errors.crownAngle}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Pavillion Angle</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="pavillionAngle"
                                            type="text"
                                            value={values.pavillionAngle}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('pavillionAngle', false)}
                                            placeholder="Enter Pavillion Angle..."
                                        />
                                        {touched.pavillionAngle && (
                                            <p className="text-red-700 error_msg">{errors.pavillionAngle}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Crown Height</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="crownHeight"
                                            type="text"
                                            value={values.crownHeight}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('crownHeight', false)}
                                            placeholder="Enter Crown Height..."
                                        />
                                        {touched.crownHeight && (
                                            <p className="text-red-700 error_msg">{errors.crownHeight}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Pavillion Height</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="pavillionHeight"
                                            type="text"
                                            value={values.pavillionHeight}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('pavillionHeight', false)}
                                            placeholder="Enter Pavillion Height..."
                                        />
                                        {touched.pavillionHeight && (
                                            <p className="text-red-700 error_msg">{errors.pavillionHeight}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Girdle</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="girdle"
                                            type="text"
                                            value={values.girdle}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('girdle', false)}
                                            placeholder="Enter Girdle..."
                                        />
                                        {touched.girdle && (
                                            <p className="text-red-700 error_msg">{errors.girdle}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Girdle Percent</label>
                                        </div>
                                        <input
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            name="girdlePer"
                                            type="text"
                                            value={values.girdlePer}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={() => setFieldTouched('girdlePer', false)}
                                            placeholder="Enter Girdle Percent..."
                                        />
                                        {touched.girdlePer && (
                                            <p className="text-red-700 error_msg">{errors.girdlePer}</p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Culet </label>
                                        </div>
                                        <input
                                            name="culet"
                                            placeholder='Enter Culet... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.culet}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('culet', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.culet && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.culet}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Laser Ins </label>
                                        </div>
                                        <Select
                                            name="laserIns"
                                            placeholder='Enter Laser Ins... '
                                            options={BooleanOptions?.map((l) => ({
                                                ...l,
                                                label: l.label,
                                                value: l.value,
                                            }))}
                                            value={values.laserIns ? {
                                                value: BooleanOptions?.find(
                                                    (e) => e.value == values.laserIns
                                                )?.value,
                                                label: BooleanOptions?.find(
                                                    (e) => e.value == values.laserIns
                                                )?.label,
                                            } : null
                                            }
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    laserIns: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('laserIns', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.laserIns && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.laserIns}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Heart & Arrow </label>
                                        </div>
                                        <input
                                            name="heartArrow"
                                            placeholder='Enter Heart & Arrow... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.heartArrow}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('heartArrow', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.heartArrow && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.heartArrow}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Report Comment </label>
                                        </div>
                                        <textarea
                                            name="reportComment"
                                            placeholder='Enter Report Comment... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.reportComment}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('reportComment', false)}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    reportComment: e.target.value,
                                                }))}}
                                        />
                                        {touched.reportComment && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.reportComment}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Key to Symbols </label>
                                        </div>
                                        <textarea
                                            name="keyToSymbol"
                                            placeholder='Enter Key to Symbols... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.keyToSymbol}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('keyToSymbol', false)}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    keyToSymbol: e.target.value,
                                                }))}}
                                        />
                                        {touched.keyToSymbol && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.keyToSymbol}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Lower </label>
                                        </div>
                                        <input
                                            name="lower"
                                            placeholder='Enter Lower... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.lower}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('lower', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.lower && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.lower}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Star </label>
                                        </div>
                                        <input
                                            name="star"
                                            placeholder='Enter Star... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.star}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('star', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.star && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.star}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Pair Pocket No </label>
                                        </div>
                                        <input
                                            name="pairPocketNo"
                                            placeholder='Enter Pair Pocket No... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.pairPocketNo}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('pairPocketNo', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.pairPocketNo && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.pairPocketNo}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >DOR </label>
                                        </div>
                                        <input
                                            name="dor"
                                            placeholder='Enter DOR... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.dor}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('dor', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.dor && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.dor}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Seal </label>
                                        </div>
                                        <input
                                            name="seal"
                                            placeholder='Enter Seal... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.seal}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('seal', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.seal && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.seal}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >CM No </label>
                                        </div>
                                        <input
                                            name="cmNo"
                                            placeholder='Enter CM No... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.cmNo}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('cmNo', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.cmNo && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.cmNo}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >FPC </label>
                                        </div>
                                        <input
                                            name="fpc"
                                            placeholder='Enter FPC... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.fpc}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('fpc', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.fpc && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.fpc}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >X-Ray </label>
                                        </div>
                                        <input
                                            name="xray"
                                            placeholder='Enter X-Ray... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.xray}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('xray', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.xray && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.xray}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Type Two Carat </label>
                                        </div>
                                        <input
                                            name="typeTwoCarat"
                                            placeholder='Enter Type Two Carat... '
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            value={values.typeTwoCarat}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('typeTwoCarat', false)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.typeTwoCarat && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.typeTwoCarat}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >SVS (GIA) </label>
                                        </div>
                                        <Select
                                            name="svs"
                                            placeholder='Enter SVS (GIA)... '
                                            options={BooleanOptions?.map((l) => ({
                                                ...l,
                                                label: l.label,
                                                value: l.value,
                                            }))}
                                            value={values.svs ? {
                                                value: BooleanOptions?.find(
                                                    (e) => e.value == values.svs
                                                )?.value,
                                                label: BooleanOptions?.find(
                                                    (e) => e.value == values.svs
                                                )?.label,
                                            } : null
                                            }
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    svs: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('svs', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.svs && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.svs}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Tracer </label>
                                        </div>
                                        <Select
                                            name="tracer"
                                            placeholder='Enter Tracer... '
                                            options={BooleanOptions?.map((l) => ({
                                                ...l,
                                                label: l.label,
                                                value: l.value,
                                            }))}
                                            value={values.tracer ? {
                                                value: BooleanOptions?.find(
                                                    (e) => e.value == values.tracer
                                                )?.value,
                                                label: BooleanOptions?.find(
                                                    (e) => e.value == values.tracer
                                                )?.label,
                                            } : null
                                            }
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    tracer: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('tracer', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.tracer && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.tracer}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-span-2">
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >DIAVIK </label>
                                        </div>
                                        <Select
                                            name="diavik"
                                            placeholder='Enter DIAVIK... '
                                            options={BooleanOptions?.map((l) => ({
                                                ...l,
                                                label: l.label,
                                                value: l.value,
                                            }))}
                                            value={values.diavik ? {
                                                value: BooleanOptions?.find(
                                                    (e) => e.value == values.diavik
                                                )?.value,
                                                label: BooleanOptions?.find(
                                                    (e) => e.value == values.diavik
                                                )?.label,
                                            } : null
                                            }
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    diavik: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('diavik', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.diavik && (
                                            <p className="text-red-700 mb-2 error_msg">
                                                {errors.diavik}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="my-4 sm:mt-6 flex justify-end gap-2 items-center bottom-0">
                                    <div>
                                        <Button
                                            type="button"
                                            className={'bg-red-500 hover:bg-red-700'}
                                            onClick={() => { { setModalCatalogue((prev) => ({ ...prev, modalState: false })) } }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Button type="submit"
                                            disabled={isSubmitting}
                                            className={'bg-green-500 hover:bg-green-700 disabled:bg-gray-300'}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </HalfGeneralSlideover>
                )}
            </Formik>
        )
    }

    return (
        <Sidebar>
            <ConfirmationDialog
                setOpen={setConfirmationDialog}
                open={confirmationDialog}
                onDelete={onDeleteCategory}
                title='Are you sure?'
                description='Do you really want to DELETE the entry?'
                confirmationButtonText='Delete'
            />
            {renderUpload()}
            {renderModal()}
            <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900">Diamonds</h1>
                    </div>
                    <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                        <Button
                            onClick={() => {
                                setModal((prev) => ({ ...prev, type: 'add', state: true }))
                            }}
                            className="flex justify-center items-center gap-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                            </svg>
                            Upload
                        </Button>
                    </div>
                </div>
                {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
        </div> */}
                <Table
                    progPage={page}
                    totalCount={count}
                    columns={diamonds_columns({ onEditOpen, onDelete })}
                    data={TabData}
                    onEditClose={cleanModalData}
                    setSearchFilter={setSearchFilter}
                />
                <nav
                    className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                >
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing{' '}
                            <select
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                    setPage(0)
                                }}
                                value={limit}
                            >
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>{' '}
                            of <span className="font-medium">{count}</span> results
                        </p>
                    </div>
                    <div className="flex items-center md:justify-end">
                        <span
                            onClick={goPrev}
                            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
                        >
                            Previous
                        </span>
                        <span className="text-sm ml-2">
                            Page{' '}
                            <input
                                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                                type="number"
                                value={page + 1}
                                onChange={(e) => setPage(e.target.value - 1)}
                                max={totalPages}
                                min="1"
                            />
                            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                        </span>
                        <span
                            onClick={goNext}
                            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
                        >
                            Next
                        </span>
                    </div>
                </nav>
            </div>
            <ToastContainer />
        </Sidebar>
    )
}

export default DiamondsPage
