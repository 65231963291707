import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  UserIcon,
  KeyIcon,
  ChevronRightIcon,
  UserGroupIcon,
  TagIcon,
  CurrencyDollarIcon,
  CloudArrowUpIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { classNames } from '../../helpers/classname'
import { Link, Navigate } from 'react-router-dom'
import logo from '../../assets/icons/Textlogo.png'
import { IoDiamond } from "react-icons/io5";
import { FaHandHolding } from "react-icons/fa";

const examNavigation = [
  {
    name: 'Inquiry',
    href: '/inquiry',
    icon: UserGroupIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Order',
    href: '/order',
    icon: TagIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Invoice',
    href: '/invoice',
    icon: CurrencyDollarIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Catalogue',
    href: '/catalogue',
    icon: CloudArrowUpIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Customer',
    href: '/customer',
    icon: UsersIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
]


const sessionTestNavigation = [
  {
    name: 'Inquiry',
    href: '/inquiry',
    icon: UserGroupIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Order',
    href: '/order',
    icon: TagIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Invoice',
    href: '/invoice',
    icon: CurrencyDollarIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Catalogue',
    href: '/catalogue',
    icon: CloudArrowUpIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Customer',
    href: '/customer',
    icon: UsersIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'User',
    href: '/users',
    icon: UserCircleIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'KYC',
    href: '/kyc',
    icon: KeyIcon,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Diamonds',
    href: '/diamonds',
    icon: IoDiamond,
    current: true,
    requiredRole: 'ADMIN',
  },
  {
    name: 'Hold Diamonds',
    href: '/hold-diamonds',
    icon: FaHandHolding,
    current: true,
    requiredRole: 'ADMIN',
  }
]

export const Sidebar = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [pathname, setPathname] = useState('/leads')
  const [moldKey, setmoldKey] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    localStorage.getItem('moldKey') ? (
      <Navigate to="/leads" />
    ) : (
      <Navigate to="/" />
    )
    setPathname(window.location.pathname)
  }, [window.location.pathname, moldKey])

  useEffect(() => {
    try {
      setmoldKey(JSON.parse(localStorage.getItem('moldKey')))
    } catch (err) { }
  }, [])

  return (
    <>
      <div className="overflow-hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-200 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-10 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-5 flex-1 overflow-y-auto">
                    <nav className="flex-3 space-y-1 h-4/6 px-2">
                      {sessionTestNavigation
                        .filter((item) =>
                          item.requiredRole.includes(moldKey.role)
                        )
                        .map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              pathname === item.href
                                ? 'bg-indigo-800 text-white'
                                : 'text-indigo-800 hover:bg-indigo-200',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-400"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ))}
                    </nav>
                    {/* <a
                      href="/logout"
                      className="text-indigo-800 hover:bg-indigo-200 group flex items-center px-2 py-2 text-sm font-medium rounded-md ml-2 mr-2"
                    >
                      <UserIcon
                        className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                        aria-hidden="true"
                      />{' '}
                      Logout
                    </a> */}
                    <Link
                      to="/logout"
                      className="px-3 text-indigo-800 hover:bg-indigo-200 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    >
                      <UserIcon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-400"
                        aria-hidden="true"
                      />
                      Logout
                    </Link>
                  </div>
                  <div></div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r-2 border-gray-200 sidebarDiv">
            <div className="flex flex-shrink-0 px-4 w-full mt-2">
              <img
                className="h-20 items-center w-52"
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="flex flex-1 flex-col mt-4">
              <nav className="flex-3 space-y-1 h-4/6 absolute overflow-y-auto w-full px-2 pb-4">
                {sessionTestNavigation
                  .filter((item) => item.requiredRole.includes(moldKey.role))
                  .map((item) => (
                    <>
                      <div key={item.name}>
                        {!item.children ? (
                          <Link
                            to={item.href}
                            className={classNames(
                              pathname === item.href
                                ? 'bg-white text-blue-900 hover:text-blue-900'
                                : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                              'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                            )}
                          >
                            <item.icon
                              // className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                              className={classNames(
                                pathname === item.href
                                  ? 'text-indigo-800'
                                  : 'text-white hover:bg-indigo-200',
                                'mr-3 h-5 w-5 flex-shrink-0'
                              )} />
                            {item.name}
                          </Link>
                        ) : (
                          <Disclosure as="div" defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    open
                                      ? 'bg-white text-blue-900 hover:text-blue-900'
                                      : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                    'w-11/12 group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                  )}
                                >
                                  <item.icon
                                    // className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                                    className={classNames(
                                      open
                                        ? 'text-indigo-800'
                                        : 'text-white hover:bg-indigo-200',
                                      'mr-3 h-5 w-5 flex-shrink-0'
                                    )} />
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? 'rotate-90 text-blue-900'
                                        : 'text-white',
                                      'ml-auto h-5 w-5 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />

                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          pathname === subItem.href
                                            ? 'bg-white text-blue-900 hover:text-blue-900'
                                            : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                          'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                        )}
                                      >
                                        <subItem.icon
                                          className={classNames(
                                            pathname === subItem.href
                                              ? 'text-indigo-800'
                                              : 'text-white hover:bg-indigo-200',
                                            'mr-3 h-5 w-5 flex-shrink-0'
                                          )}
                                          aria-hidden="true"
                                        />
                                        {subItem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </div>
                    </>
                  ))}
                {/* <div className="flex items-center justify-center text-white font-semibold text-md mt-4">
                  <hr className="w-100"></hr>
                  <p className="mx-2">Exam</p>
                  <hr className="w-100"></hr>
                </div>
                {examNavigation
                  .filter((item) => item.requiredRole.includes(moldKey.role))
                  .map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        pathname === item.href
                          ? 'bg-white text-blue-900 hover:text-blue-900'
                          : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                        'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                      )}
                    >
                      <item.icon
                        // className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                        className={classNames(
                          pathname === item.href
                            ? 'text-indigo-800'
                            : 'text-white hover:bg-indigo-200',
                          'mr-3 h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))} */}
              </nav>
              {/*   <div className='mt-auto group items-center px-2 py-2 text-sm font-medium rounded-md'>
                <div className="relative">
                  <div className={`flex items-center cursor-pointer transition-transform ${isOpen ? '-translate-y-2' : 'translate-y-0'} px-3 font-medium`} onClick={handleToggle}>
                    <UserCircleIcon className="mx-2 h-6 w-6 flex-shrink-0 text-white " aria-hidden="true" />
                    <p className="text-white text-sm font-medium">{moldKey.name}</p>
                  </div>
                  {isOpen && (
                    <div className="mt-2 w-full bg-white rounded-lg shadow-lg">
                    //  Dropdown menu content
                      <ul className="py-2">
                        <li className="px-4 py-2 hover:bg-gray-100"><p className="block mx-2 text-sm uppercase text-white text-center font-medium user_role">{moldKey.role}</p></li>
                        <li className="px-4 py-2 hover:bg-gray-100">
                          <a href="/resetpassword" className="block text-sm text-gray-700 flex">
                            <KeyIcon className="mr-3 h-5 mt-1 flex-shrink-0" />
                            <span className='font-medium'>Reset Password</span>
                          </a>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100"><a href="/logout" className="block text-sm text-gray-700 flex">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-5 mt-1 flex-shrink-0">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                          </svg>
                          <span className='font-medium'>Logout</span>
                        </a></li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>*/}
              <div className="mt-auto group items-center px-2 py-2 text-sm font-medium rounded-md">
                <p className="user_role_msg">{moldKey.role}</p>
                <Link
                  to="/resetpassword"
                  className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                >
                  <KeyIcon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                    aria-hidden="true"
                  />
                  Reset Password
                </Link>
                <Link
                  to="/logout"
                  className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                  {/* <UserIcon
                  className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-400"
                  aria-hidden="true"
                /> */}
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64 minHeight">
          <div className="sticky top-0 z-10 md:hidden flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <main className="">{props.children}</main>
        </div>
      </div>
    </>
  )
}
