import { useState, useEffect, useCallback, useMemo } from 'react'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { business_customer_column, individual_customer_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HalfGeneralSlideover from '../components/half-general-slideover'
import { classNames } from '../helpers/classname'
import { Button } from '../components/tables/paginationButtons'
import Select from 'react-select'
import Input from '../components/input'
import { Switch } from '@headlessui/react'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    email: '',
    contactNo: '',
    userType: '',
  },
}

const CustomerPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [moldKey, setmoldKey] = useState(JSON.parse(localStorage.getItem('moldKey')))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [parentEnabled, setParentEnabled] = useState(false)
  const [confirmationDiableDialog, setConfirmationDiableDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [type, setType] = useState('BUSINESS')
  // Pagination End
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [countries2, setCountries2] = useState();
  const [states2, setStates2] = useState();
  const [changeSchema, setChangeSchema] = useState(false);

  const colourStyles = {
    control: (styles, state) => ({ ...styles}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        zIndex: 1000,
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
    }),
    singleValue: (provided) => ({
      ...provided,
      color:'black',
      fontSize: '15px'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#334154', // Set the color of the placeholder text
      fontSize: '15px'
    }),
  };

  useEffect(() => {
    getAllCountries()
  },[])

  const getAllCountries = () => {
    app_api.get('auth/country')
    .then((res) => {
      setCountries(res.data.data)
      setCountries2(res.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const getState2 = (e) => {
    app_api.get(`auth/state/${e}`)
    .then((e) => {
      setStates2(e.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const handleParentCheckedChanges = () => {
    if (parentEnabled == true) {
      setParentEnabled(false)
    } else {
      setParentEnabled(true)
    }
  }

  useEffect(() => {
    getCustomerData()
  }, [page, limit, searchFilter,type])

  const cleanModalData = () => {
    setModal(initialModalState)
  }


  const getCustomerData = () => {
    let url = `/user?&size=${limit}&page=${page}&searchFilter=${searchFilter}&role=${type}`
    app_api.get(url)
      .then((res) => (res.data))
      .then((res) => {
        const data = res.data
        setTabData(data.data)
        setCount(data.count)
        setLoading(false)
        if(isNaN(data.count/limit)){setTotalPages(1)}else{setTotalPages(data.count)}
        setTotalPages(data.count / limit)
        if (data.count < 15) { setLimit(data.count) } else { setLimit(15) }
        if (data.count <= limit) setPage(0)
      }).catch((err) => {
        setError(err)
        setLoading(true)
    })
  }

  const onViewOpen = (id, index) => {
    let country1 = countries.find((e) => e.name == TabData[index].country1)?.isoCode
    let country2 = countries2.find((e) => e.name == TabData[index].country2)?.isoCode
    if(country2) getState2(country2)
    if(country1) getState(country1)
    setModal((prev) => ({
      ...prev,
      type: 'view',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setParentEnabled(TabData[index].active)
  }

  const onEnable = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }))
    setConfirmationDialog(true)
  }

  const onDisable = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }))
    setConfirmationDiableDialog(true)
  }

  const onDeleteCategory = () => {
    const { edit_id, index } = modal
    app_api
      .delete(`/user/${edit_id}`)
      .then((res) => {
        toast.success('Enabled Successfully')
        setConfirmationDialog(false)
        getCustomerData()
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onDisableUser = () => {
    const { edit_id, index } = modal
    app_api
      .put(`/user/disable/${edit_id}`)
      .then((res) => {
        toast.success('Disbaled Successfully')
        // getCategoryData()
        setConfirmationDialog(false)
        getCustomerData()
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => { 
          if(type == 'view') {
            app_api.put(`user/${edit_id}`, {...values, active: parentEnabled})
            .then((res) => {
              toast.success('User Updated Successfully')
              setModal((prev) => ({ ...prev, state: false }))
              getCustomerData()
            }).catch((err) => {
              setError(err)
              setLoading(true)
            })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={type === 'add'
            ? 'Add Customer'
            : 'Edit Customer'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
            <div className="mt-4 text-left">
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="first_name"
                      label="First Name"
                      value={values.first_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter First Name..."
                      onFocus={() => setFieldTouched('first_name', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.first_name && (
                      <p className="text-red-700 error_msg">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="last_name"
                      label="Last Name"
                      value={values.last_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Last Name..."
                      onFocus={() => setFieldTouched('last_name', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.last_name && (
                      <p className="text-red-700 error_msg">
                        {errors.last_name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="email"
                      label="Email Address"
                      value={values.email}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Email Address..."
                      onFocus={() => setFieldTouched('email', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Mobile No.
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      id="number"
                      name="number"
                      placeholder="Enter Mobile Number"
                      type="tel"
                      value={values.number}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          number: e.target.value,
                        })
                      }}
                      onFocus={() => setFieldTouched('number', false)}
                    />
                    {touched.number && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.number}
                      </p>
                    )}
                  </div>
                  {values.role == "INDIVIDUAL" &&(<>
                    <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 1
                      </label>
                      {/*  <span className="text-red-500 ml-1">*</span> */}
                    </div>
                    <Input
                      name="billaddress1"
                      // label="Address Line 1"
                      value={values.billaddress1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address Line 1..."
                    />
                    {touched.billaddress1 && (
                      <p className="text-red-700 error_msg">
                        {errors.billaddress1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Postcode
                      </label>
                      {/*  <span className="text-red-500 mx-1">*</span> */}
                    </div>
                    <input
                      name="zipCode1"
                      label="Postcode"
                      value={values.zipCode1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          zipCode1: e.target.value,
                        })
                        // checkPostCode(e.target.value, values.parentState)
                      }}
                      maxLength={4}
                      placeholder="Enter Zip Code..."
                      onFocus={() => setFieldTouched('postCode', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.zipCode1 && (
                      <p className="text-red-700 error_msg">
                        {errors.zipCode1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="city1"
                      label="City"
                      value={values.city1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter City..."
                      onFocus={() => setFieldTouched('city1', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.city1 && (
                      <p className="text-red-700 error_msg">
                        {errors.city1}
                      </p>
                    )}
                  </div>
                    </>)}
                  {values.role == 'INDIVIDUAL' && (
                    <>
                      <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country1"
                      id="country1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.country1 ?
                        {
                          value: countries?.find(
                            (e) => e.name == values.country1
                          )?.isoCode,
                          label: countries?.find(
                            (e) => e.name == values.country1
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country1: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state1"
                      id="state1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting State..."
                      placeholder="Select a state"
                      options={states?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.state1 ?
                        {
                          value: states?.find(
                            (e) => e.name == values.state1
                          )?.isoCode,
                          label: states?.find(
                            (e) => e.name == values.state1
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state1: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                    </>
                  )}
                  {values.role == 'BUSINESS' && (
                  <>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Company Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="companyName"
                      label="First Name"
                      value={values.companyName}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Company Name..."
                      onFocus={() => setFieldTouched('companyName', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.companyName && (
                      <p className="text-red-700 error_msg">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  </>
                )}
                </div>
                {values.role=="BUSINESS" &&(
                  <>
                <div className="md:mb-0 my-4 block text-2xl font-medium ">
                  Bill To
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 1
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="billaddress1"
                      label="address"
                      value={values.billaddress1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address..."
                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.billaddress1 && (
                      <p className="text-red-700 error_msg">
                        {errors.billaddress1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="billaddress2"
                      label="address"
                      value={values.billaddress2}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Address..."
                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.billaddress2 && (
                      <p className="text-red-700 error_msg">
                        {errors.billaddress2}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="city1"
                      label="city"
                      value={values.city1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter City..."
                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.city1 && (
                      <p className="text-red-700 error_msg">{errors.city1}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Zip Code
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="zipCode1"
                      label="zipCode"
                      value={values.zipCode1}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Zip Code..."
                     className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.zipCode1 && (
                      <p className="text-red-700 error_msg">
                        {errors.zipCode1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country1"
                      id="country1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.country1 ?
                        {
                          value: countries?.find(
                            (e) => e.name == values.country1
                          )?.isoCode,
                          label: countries?.find(
                            (e) => e.name == values.country1
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country1: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state1"
                      id="state1"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting State..."
                      placeholder="Select a state"
                      options={states?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.state1 ?
                        {
                          value: states?.find(
                            (e) => e.name == values.state1
                          )?.isoCode,
                          label: states?.find(
                            (e) => e.name == values.state1
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state1: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state1 && (
                        <p className="text-red-700 error_msg">
                          {errors.state1}
                        </p>
                      )}
                    </div>
                  </div>
                  </div>

                <div className="md:mb-0 my-4 block text-2xl font-medium">
                  Ship To <small>(PO Boxes not allowed)</small>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="my-2 col-md-12">
                    <label
                      className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                      for="sameAsBillTo"
                    >
                      <input
                        type="checkbox"
                        // className="absolute left-0 z-[-1] w-6 h-6  top-0"
                        className="absolute left-0 z-[-1] w-6 h-6 top-0"
                        id="enableAutoFill"
                        checked={values.sameAsBillTo}
                        onChange={(e) => {
                          setValues((val) => ({
                            ...val,
                            sameAsBillTo: e.target.checked,
                          }));
                        }}

                        disabled={
                          values.billaddress1 != undefined &&
                            values.billaddress2 != undefined &&
                            values.city1 != undefined &&
                            values.state1 != undefined &&
                            values.zipCode1 != undefined
                            ? false
                            : true
                        }
                      />
                      <span className="pl-3 font-normal  cursor-pointer select-none">
                        Same as Bill To
                      </span>
                    </label>
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-1">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 1
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="shipaddress1"
                      label="address"
                      value={
                        values.sameAsBillTo == true
                          ? values.billaddress1
                          : values.shipaddress1
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={values.sameAsBillTo}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    />
                    {touched.shipaddress1 && (
                      <p className="text-red-700 error_msg">
                        {errors.shipaddress1}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Address Line 2
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="shipaddress2"
                      label="address"
                      value={
                        values.sameAsBillTo == true
                          ? values.billaddress2
                          : values.shipaddress2
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={values.sameAsBillTo}
                      placeholder="Enter Address..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    />
                    {touched.shipaddress2 && (
                      <p className="text-red-700 error_msg">
                        {errors.shipaddress2}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="city2"
                      label="city"
                      value={values.sameAsBillTo == true ? values.city1 : values.city2}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={values.sameAsBillTo}
                      placeholder="Enter City..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    />
                    {touched.city2 && (
                      <p className="text-red-700 error_msg">{errors.city2}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Zip Code
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="zipCode2"
                      label="zipCode"
                      value={
                        values.sameAsBillTo == true ? values.zipCode1 : values.zipCode2
                      }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={values.sameAsBillTo}
                      placeholder="Enter Zip Code..."
                      className="block w-full appearance-none rounded-md border border-[#D6A093] px-3 py-3 placeholder-slate-700 bg-inherit disabled:bg-gray-100 shadow-sm focus:border-[#D6A093] focus:outline-none focus:ring-indigo-500 sm:text-sm "
                    />
                    {touched.zipCode2 && (
                      <p className="text-red-700 error_msg">
                        {errors.zipCode2}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="country2"
                      id="country2"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting Country..."
                      placeholder="Select a country"
                      options={countries2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.country2 ?
                        {
                          value: countries2?.find(
                            (e) => e.name == values.country2
                          )?.isoCode,
                          label: countries2?.find(
                            (e) => e.name == values.country2
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          country2: option.label,
                        }));
                        getState(option.value)
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.country2 && (
                        <p className="text-red-700 error_msg">
                          {errors.country2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div>
                    <div className="flex">
                        <label className="block text-sm font-medium text-gray-700">
                          State
                        </label>
                        <span className="text-white ml-1">*</span>
                      </div>
                      <Select
                      name="state2"
                      id="state2"
                      isSearchable
                      styles={colourStyles}
                      loadingMessage="Getting State..."
                      placeholder="Select a state"
                      options={states2?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      value={
                        values.state2 ?
                        {
                          value: states2?.find(
                            (e) => e.name == values.state2
                          )?.isoCode,
                          label: states2?.find(
                            (e) => e.name == values.state2
                          )?.name,
                        }
                        :
                        null
                      }
                      onChange={(option) => {
                        setValues((prev) => ({
                          ...prev,
                          state2: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                      {touched.state2 && (
                        <p className="text-red-700 error_msg">
                          {errors.state2}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                </>
                )}
                <div className="mt-4">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Activate Customer
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={parentEnabled == true}
                      onChange={() => {
                        handleParentCheckedChanges()
                      }}
                      className={classNames(
                        parentEnabled == true
                          ? 'bg-indigo-600'
                          : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          parentEnabled == true
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add'
                      : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        title = 'Are you sure?'
        description = 'Do you really want to enable the login for this user?'
        confirmationButtonText = 'Enable'
      />
      <ConfirmDialog
        setOpen={setConfirmationDiableDialog}
        open={confirmationDiableDialog}
        onDelete={onDisableUser}
        title = 'Are you sure?'
        description = 'Do you really want to disable the login for this user?'
        confirmationButtonText = 'Disable'
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex-auto ">
            <h1 className="text-3xl font-semibold text-gray-900">Customer</h1>
          </div>
        </div>
        <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-8">
        <div className="flex justify-end">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className={classNames(type=='BUSINESS'?'text-blue-700 hover:bg-blue-300 border-b-2 border-indigo-600':'',"inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10")}
                onClick={()=>setType('BUSINESS')}
              >
                Business
              </button>
              <button
                type="button"
                className={classNames(type=='INDIVIDUAL'?'text-blue-700 hover:bg-blue-300 border-b-2 border-indigo-600':'',"inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10")}
                onClick={()=>{console.log('clicked')
                  setType('INDIVIDUAL')}}
              >
                Individual
              </button>
            </span>
          </div>
        </div>
        {
          type == 'BUSINESS' ? (<>
          <Table
          progPage={page}
          totalCount={count}
          columns={business_customer_column({ onViewOpen, onEnable, onDisable })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
          </>):
          (<>
          <Table
          progPage={page}
          totalCount={count}
          columns={individual_customer_column({ onViewOpen, onEnable, onDisable })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
          </>)
        }
        <nav
          className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <select
                onChange={(e) => {
                  setLimit(e.target.value)
                  setPage(0)
                }}
                value={limit}
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div className="flex items-center md:justify-end">
            <span
              onClick={goPrev}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:duration-500 cursor-pointer"
            >
              Previous
            </span>
            <span className="text-sm ml-2">
              Page{' '}
              <input
                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span className="ml-2"></span>/ {Math.ceil(totalPages)}
            </span>
            <span
              onClick={goNext}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:duration-500 cursor-pointer"
            >
              Next
            </span>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  )
}

export default CustomerPage
