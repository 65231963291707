import { useState, useEffect, useCallback, useMemo } from 'react'
import Button from '../components/button'
import app_api from '../config/api'
import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  PhotoIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import Modal from '../components/modals'
import { Formik } from 'formik'
import Table from '../components/tables/table'
import { catalogue_column } from '../components/tables/tableheader'
import HalfGeneralSlideover from '../components/half-general-slideover'
import CurrencyInput from 'react-currency-input-field'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    fileName:null
  },
}

const initialCatalogueState = {
  type: '',
  modalState: false,
  index: null,
  id: '',
  data: {
    size_name: '',
    size_range: '',
    color: '',
    clarity: '',
    sieve: '',
    currency: '',
    individual_price: '',
    business_price: '',
    base_price: '',
    active: '',
  },
}

const CataloguePage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [modalCatalogue, setModalCatalogue] = useState(initialCatalogueState)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [confirmationActiveDialog, setConfirmationActiveDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(false)

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const getCustomerData = () => {
    let url = `/catalogue/allData/catalogue?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
    app_api.get(url)
      .then((res) => (res.data))
      .then((res) => {
        const data = res.data
        setTabData(data.data)
        setCount(data.count)
        setTotalPages(data.count / limit)
        setLoading(false)
        cleanModalData()
      }).catch((err) => {
        setError(err)
        setLoading(true)
      })
  }

  const cleanCatologueData = () => {
    setModalCatalogue(initialCatalogueState)
  }

  useEffect(() => {
    getCustomerData()
  }, [page, limit, searchFilter])

  const onEditOpen = (id, index) => {
    setModalCatalogue((prev) => ({
      ...prev,
      type: 'edit',
      id: id,
      index: index,
      modalState: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].active)
  }

  const onDelete = (id,index) => {
    setModalCatalogue((prev) => ({
      ...prev,
      id: id,
      index: index,
    }))
    setConfirmationDialog(true)
  }

  const onActive = (id,index) => {
    setModalCatalogue((prev) => ({
      ...prev,
      id: id,
      index: index,
    }))
    setConfirmationActiveDialog(true)
  }

  const onDeleteCategory = () => {
    const { id, index } = modalCatalogue
    app_api
      .delete(`/catalogue/${id}`)
      .then((res) => {
        toast.success('Catalogue marked as hidden successfully')
        getCustomerData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onActiveCategory = () => {
    const { id, index } = modalCatalogue
    app_api
      .put(`/catalogue/active/${id}`)
      .then((res) => {
        toast.success('Catalogue marked as show successfully')
        getCustomerData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const renderUpload = () => {
    const { type, state, edit_id, data } = modal
    const cleanModalData = (setValues) => {
      setValues({
        ...data,
        fileName: '',
      });
    };
    return (
      <Formik
        initialValues={{
          ...data,
          fileName: '',
        }}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
            const headers = {
              'Content-Type': 'multipart/form-data',
            }
            const formdata = new FormData()
            formdata?.append('file', values.fileName)
            app_api
              .post('catalogue', formdata, headers)
              .then((res) => {
                console.log(res)
                cleanModalData()
                getCustomerData()
              })
              .catch((err) => {
                getCustomerData()
                setError(err)
              })
          }
        }
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={'Upload Catalogue'}
            open={state}
            setOpen={() => {setModal((prev) => ({ ...prev, state: false }))
            cleanModalData(setValues)
          }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fileName: e.dataTransfer.files[0],
                    });
                  }}
                  className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                >
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-primary">
                      <label
                        htmlFor="fileName"
                        className="relative flex cursor-pointer rounded-md bg-white font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                      >
                        <span>Upload a file</span>
                        <input
                          id="fileName"
                          name="fileName"
                          type="file"
                          accept=".xlsx, .xls"
                          className="sr-only"
                          onChange={(e) => {
                            console.log(e.target.files[0])
                            setValues({
                              ...values,
                              fileName: e.target.files[0],
                            });
                          }}
                        />

                        <p className="pl-1">or drag and drop</p>
                      </label>
                    </div>
                    <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                      {values.fileName
                        ? values?.fileName?.name
                        : "XLXS"}
                      {values.fileName && (
                        <XMarkIcon
                          className="w-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            setValues({
                              ...values,
                              fileName: "",
                            });
                          }}
                        />
                      )}
                    </p>
                  </div>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >Upload
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const renderModal = () => {
    const { type, modalState, data, id } = modalCatalogue
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          app_api
            .put(`catalogue/${id}`, { ...values, base_price: parseFloat(values.base_price), 
              business_price: parseFloat(values.business_price), 
              individual_price: parseFloat(values.individual_price),
              active:enabled
            })
            .then((res) => {
              console.log(res.data)
              getCustomerData()
              toast.success(res.data.message)
              cleanCatologueData()
            }).catch((err) => {
              console.log(err)
              toast.error('Failed to update catalogue')
            })
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          setFieldTouched,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={'Catalogue'}
            open={modalState}
            setOpen={() => {
              setModalCatalogue((prev) => ({ ...prev, modalState: false }))
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="text-left mt-4">
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-1'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Size Name</label>
                    </div>
                    <input
                      name="size_name"
                      label="size_name"
                      value={values.size_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter size_name...'
                      onFocus={() => setFieldTouched('size_name', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.size_name && (
                      <p className="text-red-700 mb-1 error_msg">{errors.size_name}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Size Range</label>
                    </div>
                    <input
                      name="size_range"
                      label="size_range"
                      value={values.size_range}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter size_range...'
                      onFocus={() => setFieldTouched('size_range', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.size_range && (
                      <p className="text-red-700 mb-1 error_msg">{errors.size_range}</p>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Sieve</label>

                    </div>
                    <input
                      name="sieve"
                      placeholder='sieve'
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.sieve}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('sieve', false)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.sieve && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.sieve}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Clarity</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="clarity"
                      type="text"
                      value={values.clarity}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('clarity', false)}
                      placeholder="Enter Clarity..."
                    />
                    {touched.clarity && (
                      <p className="text-red-700 error_msg">{errors.clarity}</p>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Color</label>
                    </div>
                    <input
                      name="color"
                      placeholder='color'
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      value={values.color}
                      autoComplete="off"
                      onFocus={() => setFieldTouched('color', false)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.color && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.color}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Currency</label>
                    </div>
                    <input
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="currency"
                      type="text"
                      value={values.currency}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={() => setFieldTouched('currency', false)}
                      placeholder="Enter currency..."
                    />
                    {touched.currency && (
                      <p className="text-red-700 error_msg">{errors.currency}</p>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Base Price</label>
                    </div>
                    <CurrencyInput
                      id="base_price"
                      name="base_price"
                      value={values.base_price}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      prefix="$"
                      onValueChange={(value) => {
                        setValues((prev) => ({
                          ...prev,
                          base_price: value,
                        }))
                      }}
                    />
                    {touched.base_price && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.base_price}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Business Price</label>
                    </div>
                    <CurrencyInput
                      id="business_price"
                      name="business_price"
                      value={values.business_price}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      prefix="$"
                      onValueChange={(value) => {
                        setValues((prev) => ({
                          ...prev,
                          business_price: value,
                        }))
                      }}
                    />
                    {touched.business_price && (
                      <p className="text-red-700 error_msg">{errors.business_price}</p>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className="col-span-2">
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Individual Price</label>
                    </div>
                    <CurrencyInput
                      id="individual_price"
                      name="individual_price"
                      value={values.individual_price}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      prefix="$"
                      onValueChange={(value) => {
                        setValues((prev) => ({
                          ...prev,
                          individual_price: value,
                        }))
                      }}
                    />
                    {touched.individual_price && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.individual_price}
                      </p>
                    )}
                  </div>
                  <div className='col-span-2 mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Catalogue Status
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                </div>

                <div className="my-4 sm:mt-6 flex justify-end gap-2 items-center bottom-0">
                  <div>
                    <Button
                      type="button"
                      className={'bg-red-500 hover:bg-red-700'}
                      onClick={() => { { setModalCatalogue((prev) => ({ ...prev, modalState: false })) } }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button type="submit"
                      className={'bg-green-500 hover:bg-green-700 disabled:bg-gray-300'}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        title = 'Are you sure?'
        description = 'Do you really want to HIDE the entry?'
        confirmationButtonText = 'Hide'
      />
      <ConfirmationDialog
        setOpen={setConfirmationActiveDialog}
        open={confirmationActiveDialog}
        onDelete={onActiveCategory}
        title = 'Are you sure?'
        description = 'Do you really want to SHOW the entry?'
        confirmationButtonText = 'Show'
      />
      {renderUpload()}
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Catalogue</h1>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() => {
                setModal((prev) => ({ ...prev, type: 'add', state: true }))
              }}
              className="flex justify-center items-center gap-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
              </svg>
              Upload
            </Button>
          </div>
        </div>
        {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
        </div> */}
        <Table
          progPage={page}
          totalCount={count}
          columns={catalogue_column({ onEditOpen, onDelete, onActive })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm ml-2">
            Page{' '}
            <input
              className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
              type="number"
              value={page + 1}
              onChange={(e) => setPage(e.target.value - 1)}
              max={totalPages}
              min="1"
            />
            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  )
}

export default CataloguePage
