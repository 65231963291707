import * as Yup from 'yup'
const moment = require('moment')

export const loginSchema = Yup.object({
  email: Yup.string().email('Enter valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const ResetPasswordSchema = Yup.object({
  currentPassword: Yup.string().required('Please enter your current password'),
  password: Yup.string()
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Please enter the password again to confirm')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
})

export const userSchema = Yup.object({
  name: Yup.string()
    .required('Please enter last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter email address'),
  role: Yup.string().required('Please select the role'),
  locationId: Yup.number().required('Please select the location'),
})

export const adminSchema = Yup.object({
  first_name: Yup.string()
    .required('Please enter first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  last_name: Yup.string()
    .required('Please enter last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter email address'),
  role: Yup.string().required('Please select the role'),
})

export const invoiceSchema = Yup.object({
  invoiceDate: Yup.string()
    .required('Please select the invoice date'),
    duedate: Yup.string()
    .required('Please enter last name'),
})

export const forgotPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .required('Password is required'),
    // .matches(
    //   '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
    //   'Password must be 10 characters long and must contain 1 Special Character, 1 number and 1 Letter should be capital'
    // ),
  confirmPassword: Yup.string()
    .required('Please enter the password again to confirm')
    .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
})

export const KycSchema = Yup.object({
  companyName: Yup.string().required('Comapny Name is required'),
  companyEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
  businessTel: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
  mainLastName: Yup.string().required('Last Name is required'),
  mainFirstName: Yup.string().required('First Name is required'),
  mainEmail: Yup.string().email('Invalid Email Address').required('Email Address is required'),
  mainPhoneNo: Yup.string().required('Phone Number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  // addressLine2: Yup.string().required('Address Line 2 is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('Zip Code is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
  state: Yup.string().required('State is required'),
  resaleCertificate: Yup.string().required('This field is required'),
  applicantName: Yup.string().required('Applicant name is required'),
  refCompanyName1: Yup.string(),
  refPhoneNumber1: Yup.string(),
  pointOfContact1: Yup.string(),
  refEmail1: Yup.string().email('Invalid Email Address'),
  refCompanyName2: Yup.string().test(
    'is-unique',
    'Please use another company name',
    function (value) {
      const { refCompanyName1 } = this.parent;
      return value !== refCompanyName1;
    }
  ),
  refPhoneNumber2: Yup.string().test(
    'is-unique',
    'Please use another phone number',
    function (value) {
      const { refPhoneNumber1 } = this.parent;
      return value !== refPhoneNumber1;
    }
  ),
  pointOfContact2: Yup.string().test(
    'is-unique',
    'Please use another point of contact',
    function (value) {
      const { pointOfContact1 } = this.parent;
      return value !== pointOfContact1;
    }
  ),
  refEmail2: Yup.string().email('Invalid Email Address').test(
    'is-unique',
    'Please use another email address',
    function (value) {
      const { refEmail1 } = this.parent;
      return value !== refEmail1;
    }
  ),
  refCompanyName3: Yup.string().test(
    'is-unique',
    'Please use another company name',
    function (value) {
      const { refCompanyName1, refCompanyName2 } = this.parent;
      return value !== refCompanyName1 || value !== refCompanyName2;
    }
  ),
  refPhoneNumber3: Yup.string().test(
    'is-unique',
    'Please use another phone number',
    function (value) {
      const { refPhoneNumber1, refPhoneNumber2 } = this.parent;
      return value !== refPhoneNumber1 || value !== refPhoneNumber2;
    }
  ),
  pointOfContact3: Yup.string().test(
    'is-unique',
    'Please use another point of contact',
    function (value) {
      const { pointOfContact1, pointOfContact2 } = this.parent;
      return value !== pointOfContact1 || value !== pointOfContact2;
    }
  ),
  refEmail3: Yup.string().email('Invalid Email Address').test(
    'is-unique',
    'Please use another email address',
    function (value) {
      const { refEmail1, refEmail2 } = this.parent;
      return value !== refEmail1 || value !== refEmail2;
    }
  ),
});