import { useState, useEffect, useCallback, useMemo } from "react";
import Button from "../components/button";
import app_api from "../config/api";
import Table from "../components/tables/table";
import { PlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { order_column } from "../components/tables/tableheader";
import { Sidebar } from "../components/navigation/sidebar";
import ConfirmDialog from "../components/dialog/confirmation_dialog";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HalfGeneralSlideover from "../components/half-general-slideover";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    email: "",
    contactNo: "",
    userType: "",
  },
};

const initialInvoiceModalState = {
  invoice_type: "",
  invoice_state: false,
  invoice_index: null,
  invoice_edit_id: "",
  invoice_data: {
    name: "",
    email: "",
    contactNo: "",
    userType: "",
    message: ""
  },
};

const OrderPage = () => {
  const [modal, setModal] = useState(initialModalState);
  const [invoiceModal, setInvoiceModal] = useState(initialInvoiceModalState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [TabData, setTabData] = useState([]);
  // Pagination Start
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  // Pagination End
  const [inquiredData, setInquiredData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0)
  const [discount, setDiscount] = useState(0);
  const [discountError, setDiscountError] = useState(false);
  const [catalogue, setCatalogue] = useState([]);
  const [sizeName, setSizeName] = useState();
  const [color, setColor] = useState();
  const [clarity, setClarity] = useState();
  const [customAddError, setCustomAddError] = useState(false);
  const [user_type, setUser_Type] = useState();
  const [salestax, setsalestax] = useState(0);
  const [ischeck, setIscheck] = useState(false);
  const [customAdd, setCustomeAdd] = useState({
    size_name: "",
    color: "",
    clarity: "",
  });

  const handleCheckboxdub = () => {
    setIscheck(!ischeck);
  };

  var today = new Date().toISOString().split("T")[0];

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev);
  };

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev);
  };

  useEffect(() => {
    getInquiryData();
  }, [page, limit, searchFilter]);


  const handleClickDiv = () => {
    if (
      (customAdd.size_name != "",
      customAdd.color != "",
      customAdd.clarity != "")
    ) {
      setCustomAddError(false);
      let data = catalogue.filter(
        (e) =>
          e.size_name == customAdd.size_name &&
          e.color == customAdd.color &&
          e.clarity == customAdd.clarity
      );
      let payload = {
        catalogue: data[0],
        catalogue_id: data[0].id,
        order_id: inquiredData[0][0].order_id,
        quantity: 1,
      };
      let catalogueData = Array.from(inquiredData);
      catalogueData[0].push(payload);
      setInquiredData(catalogueData);
    } else {
      setCustomAddError(true);
    }
  };

  const cleanModalData = () => {
    setModal(initialModalState);
    setCustomeAdd({
      size_name: "",
      color: "",
      clarity: "",
    });
    setCustomAddError(false);
    setShippingCharge(0)
  };

  const cleanInvoiceModalData = () => {
    setInvoiceModal(initialInvoiceModalState);
    setIscheck(false);
    setCustomAddError(false);
  };

  const onViewOpen = (id, index) => {
    let userType = TabData[index].userType;
    setModal((prev) => ({
      ...prev,
      type: "view",
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }));
    let num = 0;
    if (TabData[index].userType == "BUSINESS") {
      TabData[index].Order_items.map((e, i) => {
        num += e.catalogue.business_price * e.quantity;
      });
    } else {
      if (TabData[index].userType == "INDIVIDUAL") {
        TabData[index].Order_items.map((e, i) => {
          num += e.catalogue.individual_price * e.quantity;
        });
      } else {
        TabData[index].Order_items.map((e, i) => {
          num += e.catalogue.base_price * e.quantity;
        });
      }
    }
    setTotalAmount(num);
    setInquiredData([TabData[index].Order_items]);
  };

  const onCancel = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }));
    setConfirmationDialog(true);
  };

  const getCatalogueData = () => {
    let uniqueValues = [];
    let uniqueColor = [];
    let uniqueClarity = [];
    let url = `/catalogue`;
    app_api
      .get(url)
      .then((res) => {
        res.data.data.forEach((obj) => {
          const value = obj.size_name;
          if (!uniqueValues.includes(value) && value != null) {
            uniqueValues.push(value);
          }
        });
        setSizeName(uniqueValues);
        res.data.data.forEach((obj) => {
          const value = obj.color;
          if (!uniqueColor.includes(value) && value != null) {
            uniqueColor.push(value);
          }
        });
        setColor(uniqueColor);
        res.data.data.forEach((obj) => {
          const value = obj.clarity;
          if (!uniqueClarity.includes(value) && value != null) {
            uniqueClarity.push(value);
          }
        });
        setClarity(uniqueClarity);
        const data = res.data;
        setCatalogue(data.data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const onDeleteCategory = () => {
    const { edit_id, index } = modal;
    app_api
      .delete(`/order/${edit_id}`)
      .then((res) => {
        toast.success("Order Cancelled Successfully");
        getInquiryData();
        setConfirmationDialog(false);
      })
      .catch((err) => {
        setError(err.toString());
        toast.error(err.response.data.message);
      });
  };

  const markAsCancelled = (edit_id) => {
    app_api
      .delete(`/order/${edit_id}`)
      .then((res) => {
        toast.success("Order Cancelled Successfully");
        setModal((prev) => ({ ...prev, state: false }));
        getInquiryData();
        setConfirmationDialog(false);
      })
      .catch((err) => {
        setError(err.toString());
        toast.error(err.response.data.message);
      });
  };

  const getInquiryData = () => {
    let url = `/order?&size=${limit}&page=${page}&searchFilter=${searchFilter}`;
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        const data = res.data;
        setTabData(data.data);
        setCount(data.count);
        setTotalPages(data.count / limit);
        setLoading(false);
        cleanModalData();
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
      });
  };

  const quantityEdit = (event, index) => {
    let data = Array.from(inquiredData);
    data[0][index].quantity = event.target.value != "" ? event.target.value : 1;
    let totalAmount = 0;

    for (let i = 0; i < data[0].length; i++) {
      totalAmount = totalAmount + data[0][i].quantity * data[0][i].amount;
    }
    setTotalAmount(totalAmount);
    if (totalAmount >= discount) {
      setDiscountError(false);
      totalAmount = discount ? totalAmount - discount : totalAmount;
    } else {
      setDiscountError(true);
    }
    // totalAmount = totalAmount + parseInt(charges)
    // setAmountPayable(totalAmount)
    setInquiredData(data);
  };

  const calculateDiscount = (value) => {
    if (value != null && value >= 0) {
      let data = Array.from(inquiredData);
      let totalAmount = 0;
      for (let i = 0; i < data[0].length; i++) {
        if (invoiceModal.invoice_data.userType == "ADMIN")
          totalAmount =
            totalAmount + data[0][i].quantity * data[0][i].catalogue.base_price;
        if (invoiceModal.invoice_data.userType == "BUSINESS")
          totalAmount =
            totalAmount +
            data[0][i].quantity * data[0][i].catalogue.business_price;
        if (invoiceModal.invoice_data.userType == "INDIVIDUAL")
          totalAmount =
            totalAmount +
            data[0][i].quantity * data[0][i].catalogue.individual_price;
      }
      if (totalAmount >= value) {
        setDiscountError(false);
        totalAmount = value ? totalAmount - value : totalAmount;
      } else {
        setDiscountError(true);
        totalAmount = totalAmount;
      }
      setDiscount(value);
      setInquiredData(data);
    } else {
      if (value == null) {
        setDiscount(0);
      }
      value = 0;
      let data = Array.from(inquiredData);
      let totalAmount = 0;
      for (let i = 0; i < data.length; i++) {
        totalAmount = totalAmount + data[i].quantity * data[i].amount;
      }
      if (totalAmount > value) {
        setDiscountError(false);
        totalAmount = value ? totalAmount - value : totalAmount;
      } else {
        setDiscountError(true);
        totalAmount = totalAmount;
      }
      setDiscount(value);
      setInquiredData(data);
    }
  };

  const markShipped = (id, index) => {
    let data = TabData[index];
    app_api
      .patch(`/order/updatestatus/${id}`, { status: "SHIPPED" })
      .then((res) => {
        getInquiryData();
        toast.success(res.data.message);
        cleanModalData();
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
      });
  };

  const markDelivered = (id, index) => {
    setUser_Type(TabData[index].inquiry.user.role);
    setInvoiceModal((val) => ({
      ...val,
      invoice_state: true,
      invoice_type: "create",
      invoice_edit_id: id,
      invoice_data: TabData[index],
    }));
    let num = 0;
    if (TabData[index].inquiry.user.role == "BUSINESS") {
      TabData[index].Order_items.map((e, i) => {
        num += e.catalogue.business_price * e.quantity;
      });
    } else {
      if (TabData[index].inquiry.user.role == "INDIVIDUAL") {
        TabData[index].Order_items.map((e, i) => {
          num += e.catalogue.individual_price * e.quantity;
          setsalestax(num % 8.875);
        });
      } else {
        TabData[index].Order_items.map((e, i) => {
          num += e.catalogue.base_price * e.quantity;
        });
      }
    }
    setTotalAmount(num);
    setDiscount(0)
    setInquiredData([TabData[index].Order_items]);
  };

  const getCustomerData = () => {
    let url = `/user/getall/data`;
    app_api
      .get(url)
      .then((res) => {
        const data = res.data;
        setCategory(data.data);
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
      });
  };

  const removeItem = (index) => {
    let data = Array.from(inquiredData);
    data[0].splice(index, 1);
    setInquiredData(data);
  };

  useEffect(() => {
    getCatalogueData();
    getCustomerData();
  }, []);

  const renderModal = () => {
    const { type, state, edit_id, data } = modal;
    data.customerId = data?.inquiry?.user_id;
    data.email = data?.inquiry?.user?.email;
    data.first_name = data?.inquiry?.user?.first_name;
    data.last_name = data?.inquiry?.user?.last_name;
    data.userType = data?.inquiry?.user?.role;
    data.description = data?.inquiry?.description;
    data.total_cost = data?.total_cost;
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          app_api
            .patch(`order/${edit_id}`, { ...values, orderItems: inquiredData })
            .then((res) => {
              cleanModalData();
              getInquiryData();
              toast.success("Order updated successfully");
            })
            .catch((err) => {
              toast.error(err.response.data.message);
            });
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={"Order Details"}
            open={state}
            setOpen={() => setModal((val) => ({
              ...val,
              state: false
            }))}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <p className="mt-6 font-semibold">Personal Details</p>
                <hr></hr>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                    </div>
                    <input
                      name="name"
                      label="name"
                      value={
                        values.first_name +
                        " " +
                        (values.last_name ? values.last_name : "")
                      }
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name..."
                      onFocus={() => setFieldTouched("name", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.name && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                    </div>
                    <input
                      name="email"
                      label="email"
                      value={values.email}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email..."
                      onFocus={() => setFieldTouched("email", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Customer
                      </label>
                    </div>
                    <input
                      name="userType"
                      label="userType"
                      value={values.userType}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter user type..."
                      onFocus={() => setFieldTouched("userType", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.userType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.userType}
                      </p>
                    )}
                  </div>
                </div>
                <p className="mt-6 font-semibold">Order items details</p>
                <hr></hr>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Size</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Color</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Clarity</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Price ( $ )</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Quantity ( CT )</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span></span>
                        </div>
                      </th>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {inquiredData[0]?.length > 0 && (
                        <>
                          {inquiredData[0].map((item, index) => (
                            <>
                              <tr key={item}>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue?.size_name}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.color}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.clarity}
                                </td>
                                {values.userType == "INDIVIDUAL" ? (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.individual_price}
                                  </td>
                                ) : (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.business_price}
                                  </td>
                                )}
                                <td className="px-4 py-3 text-sm">
                                  <input
                                    type="number"
                                    name="quantity"
                                    min={1}
                                    value={
                                      item?.quantity ? item?.quantity : null
                                    }
                                    onChange={(e) => {
                                      quantityEdit(e, index);
                                    }}
                                    onBlur={handleBlur}
                                    disabled={
                                      values.active != true ||
                                      values.status == "DELIVERED"
                                    }
                                    placeholder="Enter quantity ..."
                                    className="text-center appearance-none sm:text-sm focus:bg-none focus:outline-none focus:ring-none disabled:bg-inherit"
                                  />
                                </td>
                                <td className="text-sm text-center">
                                  <XCircleIcon
                                    className="w-8 h-8 px-1 py-1 text-red-700 hover:bg-red-700 hover:text-white hover:rounded-full hover:duration-500 hover:cursor-pointer"
                                    onClick={() => {
                                      removeItem(index);
                                    }}
                                  />
                                </td>
                              </tr>
                              <hr className="text-slate-400"></hr>
                            </>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center gap-2 h-12 mt-3">
                  <div className="w-3/12 text-black text-md h-10">
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="size_name"
                      id="size_name"
                      isSearchable
                      loadingMessage="Getting Size..."
                      placeholder="Select a Size"
                      options={sizeName?.map((l) => ({
                        ...l,
                        label: l,
                        value: l,
                      }))}
                      isDisabled={values.active != true || values.status == "DELIVERED"}
                      onChange={(option) => {
                        setCustomeAdd((prev) => ({
                          ...prev,
                          size_name: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                  </div>
                  <div className="w-3/12 text-black text-md h-10">
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="color"
                      id="color"
                      isSearchable
                      loadingMessage="Getting Color..."
                      placeholder="Select a Color"
                      options={color?.map((l) => ({
                        ...l,
                        label: l,
                        value: l,
                      }))}
                      isDisabled={values.active != true || values.status == "DELIVERED"}
                      onChange={(option) => {
                        setCustomeAdd((prev) => ({
                          ...prev,
                          color: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                  </div>
                  <div className="w-3/12 text-black text-md h-10">
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="clarity"
                      id="clarity"
                      isSearchable
                      loadingMessage="Getting Clarity..."
                      placeholder="Select a Clarity"
                      options={clarity?.map((l) => ({
                        ...l,
                        label: l,
                        value: l,
                      }))}
                      isDisabled={values.active != true || values.status == "DELIVERED"}
                      onChange={(option) => {
                        setCustomeAdd((prev) => ({
                          ...prev,
                          clarity: option.label,
                        }));
                      }}
                      onBlur={handleBlur}
                      onFocus={() => setFieldTouched("role", false)}
                    />
                  </div>
                  {values.status != "DELIVERED" && (<>
                    <div
                    className="ml-4 h-8 w-8 flex items-center justify-center rounded-full bg-indigo-900 text-white"
                    onClick={() => {
                      handleClickDiv();
                    }}
                  >
                    <PlusIcon className="w-6 h-6" />
                  </div>
                  </>)}
                </div>
                {customAddError && (
                  <span className="text-red-800 bg-red-200 px-2 py-1 text-sm rounded-full mt-5">
                    Please select all the value
                  </span>
                )}
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Note
                      </label>
                    </div>
                    <textarea
                      name="description"
                      label="description"
                      value={values.description}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          description: option.target.value,
                        });
                      }}
                      disabled={
                        values.active != true || values.status == "DELIVERED"
                      }
                      placeholder="Enter Description..."
                      onFocus={() => setFieldTouched("description", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.description && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.description}
                      </p>
                    )}
                  </div>
                </div>
                <div className="my-4 sm:mt-6 flex justify-between gap-2 items-center bottom-0">
                  <div className="flex gap-2">
                    <div>
                      <Button
                        type="button"
                        className={
                          "bg-green-700 hover:bg-green-800 disabled:bg-gray-300"
                        }
                        onClick={() => {
                          markDelivered(values.id, modal.index);
                        }}
                        disabled={
                          values.active != true || values.status == "DELIVERED"
                        }
                      >
                        Create Invoice
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="button"
                        className={
                          "bg-orange-600 hover:bg-orange-500 disabled:bg-gray-300"
                        }
                        onClick={() => {
                          markAsCancelled(edit_id);
                        }}
                        disabled={
                          values.active != true || values.status == "DELIVERED"
                        }
                      >
                        Mark as Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="flex gap-2 ">
                    <div>
                      <Button
                        type="submit"
                        className={
                          "bg-indigo-600 hover:bg-indigo-800 disabled:bg-gray-300 duration-500"
                        }
                        onClick={() => {
                          setValues({
                            ...values,
                            submitType: "Save",
                          });
                        }}
                        disabled={
                          values.active != true || values.status == "DELIVERED"
                        }
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    );
  };

  const renderInvoiceModal = () => {
    const { invoice_type, invoice_state, invoice_edit_id, invoice_data } = invoiceModal;
    invoice_data.customerId = invoice_data?.inquiry?.user_id;
    invoice_data.email = invoice_data?.inquiry?.user?.email;
    invoice_data.first_name = invoice_data?.inquiry?.user?.first_name;
    invoice_data.last_name = invoice_data?.inquiry?.user?.last_name;
    invoice_data.userType = invoice_data?.inquiry?.user?.role;
    invoice_data.description = invoice_data?.inquiry?.description;
    invoice_data.total_cost = invoice_data?.total_cost;
    return (
      <Formik
        initialValues={invoice_data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false)
          let pay = totalAmount + salestax + shippingCharge - discount
          app_api
            .post(`order/invoice/${invoice_edit_id}`, { ...values, salestax: salestax , sameAsBillto: ischeck, payableamount: pay, discount: discount, shippingCharge: shippingCharge })
            .then((res) => {
              cleanInvoiceModalData();
              getInquiryData();
              toast.success("Order updated successfully");
              setInvoiceModal((val) => ({
                ...val,
                invoice_state: false
              }))
              setSubmitting(true)
            })
            .catch((err) => {
              toast.error(err.response.data.message);
          });
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={invoice_type == "create" ? "Create Invoice" : "View Invoice"}
            open={invoice_state}
            setOpen={() => setInvoiceModal((val) => ({
              ...val,
              invoice_state: false
            }))}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <p className="mt-6 font-semibold">Personal Details</p>
                <hr></hr>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                    </div>
                    <input
                      name="name"
                      label="name"
                      value={
                        values.first_name +
                        " " +
                        (values.last_name ? values.last_name : "")
                      }
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name..."
                      onFocus={() => setFieldTouched("name", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.name && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                    </div>
                    <input
                      name="email"
                      label="email"
                      value={values.email}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email..."
                      onFocus={() => setFieldTouched("email", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Customer
                      </label>
                    </div>
                    <input
                      name="userType"
                      label="userType"
                      value={values.userType}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter user type..."
                      onFocus={() => setFieldTouched("userType", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.userType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.userType}
                      </p>
                    )}
                  </div>
                </div>
                <p className="mt-6 font-semibold">Invoice details</p>
                <hr></hr>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Date
                      </label>
                    </div>
                    <input
                      name="invoiceDate"
                      type="date"
                      max={today}
                      value={values.invoiceDate}
                      placeholder="Enter student date of birth"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.invoiceDate && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.invoiceDate}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Due Date
                      </label>
                    </div>
                    <select
                      name="state"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      id="state"
                      required
                      // onChange={(e) =>
                      //   handleBillToChange("state", e.target.value)
                      // }
                      value={values.duedate}
                      onChange={(e) => {
                        setValues((val) => ({
                          ...val,
                          duedate: e.target.value,
                        }));
                      }}
                    >
                      <option value="" selected="selected">
                        Select a duration
                      </option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                      <option value="60">60</option>
                      <option value="75">75</option>
                      <option value="90">90</option>
                      <option value="150">150</option>
                    </select>
                    {touched.duedate && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.duedate}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="my-2 col-md-12">
                      <label
                        className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                        for="sameAsBillTo"
                      >
                        <input
                          type="checkbox"
                          className="absolute left-0 z-[-1] w-5 h-5 top-0"
                          id="enableAutoFill"
                          checked={ischeck}
                          onChange={handleCheckboxdub}
                        />
                        <span className="pl-3 font-normal text-sm text-black cursor-pointer select-none">
                          Same as Bill To
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <p className="mt-6 font-semibold">Order items details</p>
                <hr></hr>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Size</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Color</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Clarity</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Price ( $ )</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Quantity ( CT )</span>
                        </div>
                      </th>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {inquiredData[0]?.length > 0 && (
                        <>
                          {inquiredData[0].map((item, index) => (
                            <>
                              <tr key={item}>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue?.size_name}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.color}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.clarity}
                                </td>
                                {values.userType == "INDIVIDUAL" ? (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.individual_price}
                                  </td>
                                ) : (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.business_price}
                                  </td>
                                )}
                                <td className="px-4 py-3 text-sm">
                                  {item.quantity}
                                </td>
                              </tr>
                              <hr className="text-slate-400"></hr>
                            </>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Message
                      </label>
                    </div>
                    <textarea
                      name="message"
                      label="description"
                      value={values.message}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          message: option.target.value,
                        });
                      }}
                      disabled={
                        values.active != true || values.status == "DELIVERED"
                      }
                      placeholder="Enter Message..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.message && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full mt-3 flex justify-end">
                  <div className="w-50 bg-gray-200 rounded-md">
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Sub Total:</p>
                      <p>${totalAmount}</p>
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Discount:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={discount}
                        disabled={
                          values.active != true || values.status == "DELIVERED"
                        }
                        maxLength={values.total_cost?.toString().length}
                        className="w-28 border-2 border-gray-300 bg-inherit appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          calculateDiscount(value);
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Shipping Charges:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={shippingCharge}
                        disabled={
                          values.active != true || values.status == "DELIVERED"
                        }
                        className="w-28 border-2 border-gray-300 bg-inherit appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          if(value != undefined) setShippingCharge(parseInt(value));
                          else setShippingCharge(0)
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Sale Tax:</p>
                      <p>${salestax}</p>
                    </div>
                    <hr className="mt-2"></hr>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Total Payable:</p>
                      <p>${totalAmount + salestax + shippingCharge - discount}</p>
                    </div>
                  </div>
                </div>
                {discountError && (
                  <>
                    <p className="text-sm text-red-600 text-right">
                      Discount is more then Total Payable Amount
                    </p>
                  </>
                )}

                <div className="my-4 sm:mt-6 flex justify-end gap-2 items-center bottom-0">
                  <div className="flex gap-2 ">
                    <div>
                      <Button
                        type="submit"
                        className={
                          "bg-indigo-600 hover:bg-indigo-800 disabled:bg-gray-300 duration-500"
                        }
                        onClick={() => {
                          setValues({
                            ...values,
                            submitType: "Save",
                          });
                        }}
                        disabled={
                          values.status == "DELIVERED"
                        }
                      >
                        Create Invoice
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    );
  };

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        description="Do you really want to mark the order as cancel?"
        confirmationButtonText="Cancel"
      />
      {renderModal()}
      {renderInvoiceModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Orders</h1>
          </div>
        </div>
        <Table
          progPage={page}
          totalCount={count}
          columns={order_column({
            onViewOpen,
            onCancel,
            markShipped,
            markDelivered,
          })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
          className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div>
            <p className="text-sm text-gray-700">
              Showing{" "}
              <select
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(0);
                }}
                value={limit}
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{" "}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div className="flex items-center md:justify-end">
            <span
              onClick={goPrev}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
            >
              Previous
            </span>
            <span className="text-sm ml-2">
              Page{" "}
              <input
                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span className="ml-2"></span>/ {Math.ceil(totalPages)}
            </span>
            <span
              onClick={goNext}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
            >
              Next
            </span>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  );
};

export default OrderPage;
