export const editorConfig = {
  zIndex: 0,
  readonly: false,
  activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
  toolbarButtonSize: 'middle',
  theme: 'default',
  enableDragAndDropFileToEditor: true,
  saveModeInCookie: false,
  spellcheck: true,
  editorCssClass: false,
  triggerChangeEvent: true,
  height: 220,
  direction: 'ltr',
  language: 'en',
  debugLanguage: false,
  i18n: 'en',
  tabIndex: -1,
  toolbar: true,
  enter: 'P',
  useSplitMode: false,
  colorPickerDefaultTab: 'background',
  imageDefaultWidth: 100,
  removeButtons: [
    'source',
    'fullsize',
    'about',
    'outdent',
    'indent',
    'video',
    'print',
    'superscript',
    'subscript',
    'file',
    'cut',
    'selectall',
  ],
  disablePlugins: ['paste', 'stat'],
  events: {},
  textIcons: false,
  uploader: {
    url: `${process.env.REACT_APP_BACKEND_URL}/question/upload`, //your upload api url
    insertImageAsBase64URI: false,
    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    //headers: {"token":`${db.token}`},
    filesVariableName: function (t) {
      return 'files[' + t + ']'
    }, //"files",
    withCredentials: false,
    pathVariableName: 'path',
    format: 'json',
    method: 'POST',
    prepareData: function (formData) {
      var file = formData.getAll('files[0]')[0]
      formData.delete('files[0]')
      formData.append('files', file)
      formData.delete('path')
      formData.delete('source')
      return formData
    },
    isSuccess: function (e) {
      return e.success
    },
    getMessage: function (e) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : ''
    },
    process: function (resp) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = []
      files.unshift(resp.data)
      return {
        files: resp.data,
        error: resp.error,
        msg: resp.msg,
      }
    },
    error: function (e) {
      this.j.e.fire('errorMessage', e.message, 'error', 4000)
    },
    defaultHandlerSuccess: function (resp) {
      // `this` is the editor.
      const j = this
      if (resp?.files && resp.files.length) {
        const tagName = 'img'
        resp?.files.forEach((file, index) => {
          //edetor insertimg function
          const elm = j.createInside.element(tagName)
          elm.setAttribute(
            'src',
            `${process.env.REACT_APP_STATIC_URL}${file.path}`
          )
          j.s.insertImage(elm, null, j.o.imageDefaultWidth)
        })
      }
    },
    defaultHandlerError: function (e) {
      let _ = this
      _.j.e.fire('errorMessage', e.message)
    },
    contentType: function (e) {
      return (
        (void 0 === this.jodit.ownerWindow.FormData || 'string' == typeof e) &&
        'application/x-www-form-urlencoded; charset=UTF-8'
      )
    },
  },
  showXPathInStatusbar: false,
}
