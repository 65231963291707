import { Navigate, Outlet } from 'react-router-dom'
import React from 'react'
const userAuth = () => {
  return true
}
const ProtectedRoutes = () => {
  const isAuth = userAuth()
  return isAuth ? <Outlet /> : <Navigate to="/logout" />
}

export default ProtectedRoutes
