import Button from '../button'
import { Formik } from 'formik'
import { loginSchema } from '../../schema'
import logo from '../../assets/icons/logo.png'
import Loginbg from '../../assets/loginBg.jpg'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { forgotPasswordSchema } from '../../schema'
import Modal from '../modals'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    forgotEmail: '',
  },
}

const Login = ({
  loading,
  loader,
  statusCode,
  forgotError,
  error,
  login,
  forgotPasssword,
  success,
}) => {
  const navigate = useNavigate()
  const [moldKey, setmoldKey] = useState(localStorage.getItem('moldKey'))
  const [modal, setModal] = useState(initialModalState)

  useEffect(() => {
    localStorage.getItem('moldKey') && navigate('/')
  }, [moldKey])

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const renderModal = () => {
    const { type, state, data } = modal
    return (
      <Formik
        initialValues={{ forgotEmail: '' }}
        validationSchema={forgotPasswordSchema}
        enableReinitialize
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title="Forgot Password"
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form noValidate>
              <div className="text-left mt-4">
                <div className="mb-4">
                  {forgotError !== null && (
                    <p className="text-red-700 error_msg error_text">
                      {forgotError}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  {success !== null && <p className="success_msg">{success}</p>}
                </div>
                <div>
                  <label
                    htmlFor="forgotEmail"
                    className="block text-sm font-medium text-gray-700 loginFontSize"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="forgotEmail"
                      placeholder="Enter your Email"
                      name="forgotEmail"
                      type="forgotEmail"
                      autoComplete
                      required={true}
                      value={values.forgotEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                    />
                    {touched.forgotEmail && (
                      <p className="text-red-700 error_msg">
                        {errors.forgotEmail}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button
                    disabled={isSubmitting}
                    loading={loader}
                    onClick={() => {
                      forgotPasssword(values)
                    }}
                  >
                    Request
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
  return (
    <>
      {renderModal()}
      <div className="flex min-h-screen">
        <div className="flex flex-col mainFormBody">
          <div className=" h-100 w-full  ">
            <div>
              <img className="Signin_logo " src={logo} alt="Your Company" />
            </div>
            {statusCode == 0 && (
              <div className="mt-8">
                <div className="mt- flex ">
                  <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={loginSchema}
                    onSubmit={login}
                  >
                    {({
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      isValid,
                    }) => (
                      <form
                        className="space-y-6 w-full SignInForm"
                        onSubmit={handleSubmit}
                        noValidate={true}
                      >
                        <h2 className="mt-6 text-4xl font-bold tracking-tight text-gray-900 LoginMainText">
                          Log in
                        </h2>
                        <div
                          className="LoginSubText"
                          style={{ marginTop: '10px' }}
                        >
                          <span>Welcome back! Please enter your details.</span>
                        </div>
                        <div>
                          {error !== null && (
                            <p className="text-red-700 error_msg error_text">
                              {error}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 loginFontSize"
                          >
                            Email
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              placeholder="Enter your email"
                              name="email"
                              type="email"
                              autoComplete
                              required={true}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                            />
                            {touched.email && (
                              <p className="text-red-700 error_msg">
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div>
                            <label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700 loginFontSize"
                            >
                              Password
                            </label>
                            <div className="mt-1">
                              <input
                                id="password"
                                placeholder="*****"
                                name="password"
                                type="password"
                                autoComplete
                                required={true}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                              />
                              {touched.password && (
                                <p className="text-red-700 error_msg">
                                  {errors.password}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end -mt-1">
                          <a
                            className="text-red-400 font-semibold cursor-pointer hover:text-red-500"
                            onClick={() =>
                              setModal((prev) => ({
                                ...prev,
                                type: 'add',
                                state: true,
                              }))
                            }
                          >
                            Forgot Password
                          </a>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            loading={loading}
                            className="text-center"
                          >
                            Sign In
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
          <div className="ml-4 absolute bottom-0">
            <span
              style={{
                color: '#667085',
                fontSize: '14px',
              }}
            >
              © Solitaire Planet 2023
            </span>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 md:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={Loginbg}
            alt=""
          />
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}

export default Login
