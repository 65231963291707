import moment from "moment";
import Button from "../button";
import { DocumentArrowDownIcon } from "@heroicons/react/20/solid";
import {PlayCircleIcon} from "@heroicons/react/24/outline";

export const user_column = ({
  onEditOpen,
  onDeleteOpen,
  onStatusInactiveOpen,
  onStatusActiveOpen,
}) => [
  {
    Header: "Name",
    accessor: "name",
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index);
                  }}
                >
                  {cell.row.original.first_name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      );
    },
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    Cell: (cell) => {
      return (
        <>
          <span className="">
            {cell.row.original.role.charAt(0).toUpperCase() +
              cell.row.original.role.slice(1)}
          </span>
        </>
      );
    },
  },
  {
    Header: "Status",
    id: "status",
    accessor: (d) =>
      d.active == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.is_active == true && (
            <span className="inline-flex px-2 mr-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onStatusInactiveOpen(cell.row.original.id, cell.row.index);
                }}
                className="text-red-700 "
                size="default"
                variant="outlined"
              >
                Inactive<span className="sr-only">, </span>
              </Button>
            </span>
          )}
          {cell.row.original.is_active == false && (
            <span className="inline-flex px-2 mr-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  onStatusActiveOpen(cell.row.original.id, cell.row.index);
                }}
                className="text-green-500 "
                size="default"
                variant="outlined"
              >
                Active<span className="sr-only">, </span>
              </Button>
            </span>
          )}

          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      );
    },
  },
];

export const inquiry_customer = ({ onViewOpen, onCancel }) => [
  {
    Header: "Name",
    accessor: (d) =>
      d.user
        ? d.user.first_name + " " + (d.user.last_name ? d.user.last_name : "")
        : "-",
  },
  {
    Header: "Email",
    accessor: (d) => (d.user ? d.user.email : "-"),
  },
  {
    Header: "Description",
    accessor: (d) => (d.description ? d.description : "-"),
  },
  {
    Header: "Status",
    accessor: (d) =>
      d.active == true && d.order_created == false ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            NEW
          </span>
        </>
      ) : d.active == true && d.order_created == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ORDER CREATED
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            CANCELLED
          </span>
        </>
      ),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onViewOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.active == true &&
            cell.row.original.order_created == false && (
              <>
                <span className="inline-flex px-2 ml-3 text-sm leading-5 text-white bg-orange-500 hover:bg-orange-400 duration-500 rounded-full">
                  <button
                    onClick={() => {
                      onCancel(cell.row.original.id, cell.row.index);
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Mark as Cancelled
                    <span className="sr-only">, </span>
                  </button>
                </span>
              </>
            )}
        </>
      );
    },
  },
];

export const business_customer_column = ({ onViewOpen, onEnable, onDisable }) => [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Contact Number",
    accessor: "number",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Company Name",
    accessor: "companyName",
  },
  {
    Header: "Address",
    accessor: "billaddress1",
  },
  
  {
    Header: "City",
    accessor: "city1",
  },
  {
    Header: "Country",
    accessor: "country1",
  },
  {
    Header: "State",
    accessor: "state1",
  },
  {
    Header: "Zip Code",
    accessor: "zipCode1",
  },
  {
    Header: "Status",
    accessor: (d) =>
      d.active == true ? (
        <>
          <span className="text-green-500 rounded-full text-sm font-semibold">
            Enabled
          </span>
        </>
      ) : (
        <>
          <span className="text-red-600 text-sm font-semibold rounded-full">
            Disabled
          </span>
        </>
      ),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onViewOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.active == true ? 
          (<>
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDisable(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Disable
              <span className="sr-only">, </span>
            </Button>
          </span>
          </>)
        : (<>
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onEnable(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Enable
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>)}
          
        </>
      );
    },
  },
];

export const individual_customer_column = ({ onViewOpen, onEnable, onDisable }) => [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Contact Number",
    accessor: "number",
  },
  {
    Header: "Address",
    accessor: "billaddress1",
  },
  {
    Header: "Zip Code",
    accessor: "zipCode1",
  },
  {
    Header: "Country",
    accessor: "country1",
  },
  {
    Header: "State",
    accessor: "state1",
  },
  {
    Header: "City",
    accessor: "city1",
  },
  {
    Header: "Status",
    accessor: (d) =>
      d.active == true ? (
        <>
          <span className="text-green-500 rounded-full text-sm font-semibold">
            Enabled
          </span>
        </>
      ) : (
        <>
          <span className="text-red-600 text-sm font-semibold rounded-full">
            Disabled
          </span>
        </>
      ),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onViewOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.active == true ? 
          (<>
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDisable(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Disable
              <span className="sr-only">, </span>
            </Button>
          </span>
          </>)
        : (<>
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onEnable(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Enable
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>)}
          
        </>
      );
    },
  },
];

export const catalogue_column = ({ onEditOpen, onDelete, onActive }) => [
  {
    Header: "Size Name",
    accessor: "size_name",
  },
  {
    Header: "Size Range",
    accessor: "size_range",
  },
  {
    Header: "Sieve",
    accessor: "sieve",
  },
  {
    Header: "Clarity",
    accessor: "clarity",
  },
  {
    Header: "Color",
    accessor: "color",
  },

  // {
  //   Header: 'Currency',
  //   accessor: 'currency',
  // },

  {
    Header: "Base Price",
    accessor: "base_price",
  },

  {
    Header: "Business Price",
    accessor: "business_price",
  },

  {
    Header: "Individual Price",
    accessor: "individual_price",
  },
  {
    Header: "Status",
    accessor: (d) =>
      d.active == true ? (
        <>
          <span className="text-green-500 rounded-full text-sm font-semibold">
            SHOW
          </span>
        </>
      ) : (
        <>
          <span className="text-red-600 text-sm font-semibold rounded-full">
            HIDDEN
          </span>
        </>
      ),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full px-2">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.active != true ? (
            <>
              <span className="inline-flex ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full px-2">
                <Button
                  onClick={() => {
                    onActive(cell.row.original.id, cell.row.index);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Show
                  <span className="sr-only">, </span>
                </Button>
              </span>
            </>
          ) : (
            <>
              <span className="inline-flex ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full px-2">
                <Button
                  onClick={() => {
                    onDelete(cell.row.original.id, cell.row.index);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Hide
                  <span className="sr-only">, </span>
                </Button>
              </span>
            </>
          )}
        </>
      );
    },
  },
];

export const order_column = ({onViewOpen,onCancel,markShipped,markDelivered,}) => [
  {
    Header: "Name",
    accessor: (d) =>
      d?.inquiry
        ? d?.inquiry?.user?.first_name + " " + d?.inquiry?.user?.last_name
        : "-",
  },
  {
    Header: "Email",
    accessor: (d) => (d?.inquiry ? d?.inquiry?.user?.email : "-"),
  },
  {
    Header: "Description",
    accessor: (d) => (d.description ? d?.description : "-"),
  },
  {
    Header: "Status",
    accessor: (d) =>
      d.status ? (
        d?.status == "CANCELLED" ? (
          <>
            <p className="text-red-600 font-semibold rounded-md">CANCELLED</p>
          </>
        ) : d?.status == "DELIVERED" ? (
          <>
            <p className="text-green-600 font-semibold rounded-md">
              {d?.status}
            </p>
          </>
          ) : d?.status == "NEW" ? (
          <>
            <p className="text-orange-600 font-semibold rounded-md">
              {d?.status}
            </p>
          </>) : d?.status == "PROCESSED" ? (
          <>
            <p className="text-blue-600 font-semibold rounded-md">
              {d?.status}
            </p>
          </>
        ) : (
          d?.status
        )
      ) : (
        "-"
      ),
  },
  {
    Header: "Total Price",
    accessor: (d) => (d.total_cost ? "$" + d?.total_cost : "-"),
  },
  {
    Header: "Total Item",
    accessor: (d) => (d.total_items ? d?.total_items : "-"),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onViewOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.status === "NEW" && (
            <>
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onCancel(cell.row.original.id, cell.row.index);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Mark as Cancel
                  <span className="sr-only">, </span>
                </Button>
              </span>
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
                <Button
                  onClick={() => {
                    markDelivered(cell.row.original.id, cell.row.index);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Create Invoice
                  <span className="sr-only">, </span>
                </Button>
              </span>
            </>
          )}
        </>
      );
    },
  },
];

export const invoice_column = ({onWriteoff, onpayment, onPDFOpen, onViewOpen, onCancel }) => [
  {
    Header: "Invoice Number",
    accessor: "invoice_no",
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            <span className="inline-flex leading-5" onClick={() => {onPDFOpen(cell.row.original.id, cell.row.index);}}>
                <DocumentArrowDownIcon
                  className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4 cursor-pointer"
                  aria-hidden="true"
                />
              <p className="hover:text-indigo-800 cursor-pointer text-base">
                SPINV-{cell.row.original.id}
                <span className="sr-only">, </span>
              </p>
            </span>
          </div>
        </>
      );
    },
  },
  {
    Header: "Customer Name",
    accessor: (d) =>
      d?.order?.inquiry?.user ? d?.order?.inquiry?.user?.first_name + " " + d?.order?.inquiry?.user.last_name : "-",
  },
  {
    Header: "Email",
    accessor: (d) => (d?.order?.inquiry?.user ? d?.order?.inquiry?.user?.email : "-"),
  },
  {
    Header: "Status",
    accessor: (d) => (d.status ? d?.status : "-"),
  },
  {
    Header: "Total Amount",
    accessor: (d) => (d.totalamount ? "$" + d?.totalamount : 0),
  },
  {
    Header: "Discount",
    accessor: (d) => (d.discount ? "$" + d?.discount : 0),
  },
  {
    Header: "Sales Tax",
    accessor: (d) => (d.salestax ? "$" + d?.salestax : 0),
  },
  {
    Header: "Shipping Charges",
    accessor: (d) => (d.shippingCharge ? "$" + d?.shippingCharge : 0),
  },
  {
    Header: "Payable Amount",
    accessor: (d) => (d.payableamount ? "$" + d?.payableamount : 0),
  },
  {
    Header: "Balance Amount",
    accessor: (d) => (d.balance_amount ? "$" + d?.balance_amount : 0),
  },
  {
    Header: "Invoice Date",
    accessor: (d) => (d.invoice_date ? moment(d?.invoice_date).format("DD MMM YYYY") : "-"),
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
        {/* <span className="inline-flex leading-5">
          <a href={`${cell.row.original.url}`} target="_blank ">
            <span className="text-indigo-600  hover:text-indigo-800 p-2 rounded-lg">
              Download
            </span>
          </a>
        </span> */}
         <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full cursor-pointer">
         <Button
           onClick={() => {
             onViewOpen(cell.row.original.id, cell.row.index);
           }}
           className="text-indigo-900 "
           size="default"
           variant="outlined"
         >
           View<span className="sr-only">, </span>
         </Button>
       </span>
       <span className="inline-flex px-2 text-sm leading-5 text-blue-600 bg-blue-100 rounded-full ml-2 cursor-pointer">
         <Button
           onClick={() => {
             onPDFOpen(cell.row.original.id, cell.row.index);
           }}
           className="text-indigo-900 "
           size="default"
           variant="outlined"
         >
           Download<span className="sr-only">, </span>
         </Button>
       </span>
      {
        cell.row.original.status == 'AWATING PAYMENT' && (<>
          <span className="inline-flex px-2 text-sm leading-5 text-red-500 bg-red-100 rounded-full ml-2 cursor-pointer">
         <Button
           onClick={() => {
             onWriteoff(cell.row.original.id, cell.row.index);
           }}
           className="text-black "
           size="default"
           variant="outlined"
         >
           Write-Off<span className="sr-only">, </span>
         </Button>
       </span>
       <span className="inline-flex px-2 text-sm leading-5 text-purple-500 bg-purple-100 rounded-full ml-2 cursor-pointer">
         <Button
           onClick={() => {
             onpayment(cell.row.original.id, cell.row.index);
           }}
           className="text-black "
           size="default"
           variant="outlined"
         >
           Register a Payment<span className="sr-only">, </span>
         </Button>
       </span>
        </>
      )}
       </>
      );
    },
  },
];

export const kyc_column = ({ onViewOpen, onDownload,approveKyc,onDelete }) => [
  {
    Header: "KYC Info",
    accessor: "invoice_no",
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            <span className="inline-flex leading-5">
                <DocumentArrowDownIcon
                  className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                  aria-hidden="true"
                  onClick={() => {
                    onDownload(cell.row.original.id, cell.row.index);
                  }}
                />
              <p className="hover:text-indigo-800 cursor-pointer text-base">
                {cell.row.original.user?.kyc_status == true ? 'KYC Approved' : 'KYC Pending'}
                <span className="sr-only">, </span>
              </p>
            </span>
          </div>
        </>
      );
    },
  },
  {
    Header: "Customer Name",
    accessor: (d) =>
      d?.user ? d?.user?.first_name + " " + d.user.last_name : "-",
  },
  {
    Header: "Company Name",
    accessor: (d) =>
      d?.user ? d?.companyName : "-",
  },
  {
    Header: "Trade Name",
    accessor: (d) =>
      d?.user ? d?.tradeName : "-",
  },
  {
    Header: "Company Email",
    accessor: (d) =>
      d?.user ? d?.companyEmail : "-",
  },
  {
    Header: "Company Phone Number",
    accessor: (d) =>
      d?.user ? d?.businessTel : "-",
  },
  
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
         <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
         <Button
           onClick={() => {
             onViewOpen(cell.row.original.id, cell.row.index);
           }}
           className="text-indigo-900 "
           size="default"
           variant="outlined"
         >
           View<span className="sr-only">, </span>
         </Button>
       </span>
       {cell.row.original.user?.kyc_status == false  &&
       <>
       <span className="inline-flex px-2 text-sm leading-5 rounded-full text-green-600 hover:text-green-800  bg-green-100">
         <Button
           onClick={() => {
            approveKyc(cell.row.original.userId, cell.row.index);
           }}
           size="default"
           variant="outlined"
         >
           Approve KYC<span className="sr-only">, </span>
         </Button>
       </span>
         <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
         <Button
           onClick={() => {
            onDelete(cell.row.original.id, cell.row.index);
           }}
           size="default"
           variant="outlined"
         >
           Delete<span className="sr-only">, </span>
         </Button>
       </span>

       </>
       }
       </>
      );
    },
  },
];

export const diamonds_columns = ({ onEditOpen, onDelete }) => [
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDelete(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              Delete<span className="sr-only">, </span>
            </Button>
          </span>

        </>
      );
    },
  },
  {
    Header:"SR No",
    Cell:(cell)=>{
      return(cell.row.index + 1)
    }
  },
  {
    Header: "Quantity",
    accessor:"quantity"
  },
  {
    Header: "Packet No",
    accessor: "packetNo",
  },
  {
    Header: "Report No",
    accessor: "reportNo",
  },
  {
    Header: "Lab",
    accessor: "lab",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.reportNo ? (
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <a
                href={`https://www.gia.edu/report-check?reportno=${cell.row.original.reportNo}`}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
                target="_blank"
              >
                {cell.row.original.lab}<span className="sr-only">, </span>
              </a>
            </span>):(
              <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
                {cell.row.original.lab}
              </span>
            )}
        </>
      );
    },
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "DNA",
    Cell: (cell) => {
      return (
        <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
          <a
            href={`https://views.diadna.com/dna/${cell.row.original.packetNo}`}
            className="text-indigo-900 "
            size="default"
            variant="outlined"
            target="_blank"
          >
            DNA<span className="sr-only">, </span>
          </a>
        </span>
      );
    },
  },
  {
    Header: "Video",
    Cell: (cell) => {
      return (
        <a
          href={`https://views.diadna.com/diamondfullview/${cell.row.original.packetNo}`}
          className="text-indigo-900 flex justify-center"
          size="default"
          variant="outlined"
          target="_blank"
        >
          <PlayCircleIcon className="w-6" />
        </a>
      );
    },
  },
  {
    Header: "Naked Eye",
    Cell: (cell) => {
      return (
        <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
          <a
            href={`https://views.diadna.com/diamondVideoview/${cell.row.original.packetNo}`}
            className="text-indigo-900 "
            size="default"
            variant="outlined"
            target="_blank"
          >
            Naked Eye<span className="sr-only">, </span>
          </a>
        </span>
      );
    },
  },
  {
    Header: "Shape",
    accessor: "shape",
  },
  {
    Header: "Carat",
    accessor: d => '$ ' + d.carat.toFixed(2) ,
  },
  // {
  //   Header: "Availablity",
  //   accessor: "shape",
  // },
  {
    Header: "Color",
    accessor: "color",
  },
  {
    Header: "Clarity",
    accessor: "clarity",
  },
  {
    Header: "Shade",
    accessor: "shade",
  },
  {
    Header: "Rap",
    accessor: "rap",
  },
  {
    Header: "Discount",
    accessor: d =>  d.discount.toFixed(2) + ' %',
  },
  {
    Header: "Price Per Carat",
    accessor: d => '$ ' + d.price.toFixed(2) ,
  },
  {
    Header: "Amount",
    accessor: d => '$ ' + (d.price * d.carat).toFixed(2),
  },
  {
    Header: "Cut",
    accessor: "cut",
  },
  {
    Header: "Polish",
    accessor: "polish",
  },
  {
    Header: "Symmetry",
    accessor: "symmetry",
  },
  {
    Header: "Fluorescence",
    accessor: "fluorescence",
  },
  {
    Header: "Brilliancy",
    accessor: "brilliancy",
  },
  {
    Header: "Length",
    accessor: "length",
  },
  {
    Header: "Width",
    accessor: "width",
  },
  {
    Header: "Depth",
    accessor: "depth",
  },
  {
    Header: "Table %",
    accessor: "tableper",
  },
  {
    Header: "Depth %",
    accessor: "depthper",
  },
  {
    Header: "Ratio",
    accessor: "ratio",
  },
  {
    Header: "Center Natts",
    accessor: "centerNatts",
  },
  {
    Header: "Side Natts",
    accessor: "sideNatts",
  },
  {
    Header: "Center White",
    accessor: "centerWhite",
  },
  {
    Header: "Side White",
    accessor: "sideWhite",
  },
  {
    Header: "Table Open",
    accessor: "tableOpen",
  },
  {
    Header: "Crown Open",
    accessor: "crownOpen",
  },
  {
    Header: "Pavillion Open",
    accessor: "pavillionOpen",
  },
  {
    Header: "Eye Clean",
    accessor: (d) => d.EyeClean ? 'Yes' : 'No',
  },
  {
    Header: "Crown Angle",
    accessor: "crownAngle",
  },
  {
    Header: "Pavillion Angle",
    accessor: "pavillionAngle",
  },
  {
    Header: "Crown Height",
    accessor: "crownHeight",
  },
  {
    Header: "Pavillion Height",
    accessor: "pavillionHeight",
  },
  {
    Header: "Girdle",
    accessor: "girdle",
  },
  {
    Header: "Girdle %",
    accessor: "girdlePer",
  },
  {
    Header: "Culet",
    accessor: "culet",
  },
  {
    Header: "Laser Ins",
    accessor: (d) => d.laserIns ? 'Yes' : 'No',
  },
  {
    Header: "Heart Arrow",
    accessor: "heartArrow",
  },
  {
    Header: "Report Comment",
    accessor: "reportComment",
  },
  {
    Header: "Key to Symbols",
    accessor: "keyToSymbol",
  },
  {
    Header: "Lower",
    accessor: "lower",
  },
  {
    Header: "Star",
    accessor: "star",
  },
  {
    Header: "Pair Pocket No",
    accessor: "pairPocketNo",
  },
  {
    Header: "DOR",
    accessor: "dor",
  },
  {
    Header: "Seal",
    accessor: "seal",
  },
  {
    Header: "CM No",
    accessor: "cmNo",
  },
  {
    Header: "FPC",
    accessor: "fpc",
  },
  {
    Header: "Diamond Status",
    accessor: "status",
  },
  {
    Header: "X-ray",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.xray && (
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <a
                href={`https://client.belgiumny.com/xrays/${cell.row.original.packetNo}`}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
                target="_blank"
              >
                X-Ray<span className="sr-only">, </span>
              </a>
            </span>
          )}
        </>
      );
    },
  },
  {
    Header: "Type Two Carat",
    accessor: "typeTwoCarat",
  },
  {
    Header: "SVS",
    accessor: (d) => d.svs ? d.svs ? 'Yes' : 'No' : null,
  },
  {
    Header: "Tracer",
    accessor: (d) => d.tracer ? d.tracer ? 'Yes' : 'No' : null,
  },
  {
    Header: "Diavik",
    accessor: (d) => d.diavik ? d.diavik ? 'Yes' : 'No' : null,
  },
];

export const holden_diamonds_columns = ({ onEditOpen, onDelete, updateToHold }) => [
  {
    Header: "Customer",
    accessor: (d) => d.user.role === 'INDIVIDUAL' ? d?.user?.first_name + ' ' + d.user?.last_name : d?.user?.companyName,
  },
  {
    Header: "Hold Type",
    accessor: "holdType"
  },
  {
    Header: "Hold Till",
    accessor: d => d.holdtill ? moment(d.holdtill).format('DD-MM-YYYY'):'-',
  },
  {
    Header: "Comment",
    accessor: "comments"
  },
  {
    Header: 'Packet Number',
    accessor: d => d.holdenStocks.map((d) => d.diamond?.packetNo).join(', ')
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index);
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>{cell.row.original.status !== 'TIME_EXPIRED' && <>
            {cell.row.original.status === 'HOLD' ? (
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onDelete(cell.row.original.id, cell.row.index);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Unhold<span className="sr-only">, </span>
                </Button>
              </span>
            ) : <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  updateToHold(cell.row.original.id);
                }}
                size="default"
                variant="outlined"
              >
                Hold<span className="sr-only">, </span>
              </Button>
            </span>}
          </>}
        </>
      );
    },
  },
]
