import { useState, useEffect } from 'react'
import Button from '../components/button'
import app_api from '../config/api'
import { Sidebar } from '../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik } from 'formik'
import Table from '../components/tables/table'
import { holden_diamonds_columns } from '../components/tables/tableheader'
import HalfGeneralSlideover from '../components/half-general-slideover'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Select from 'react-select'
import { XCircleIcon } from '@heroicons/react/24/outline'

const initialModalState = {
    type: '',
    state: false,
    index: null,
    edit_id: '',
    data: {
        user_id: '',
        comments: '',
        status: '',
        holdtill: '',
        holdType: '',
    },
}

const initialCatalogueState = {
    type: '',
    modalState: false,
    index: null,
    id: '',
    data: {
        user_id: '',
    },
}

const BooleanOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
]

const HoldPage = () => {
    const [modal, setModal] = useState(initialModalState)
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const [searchFilter, setSearchFilter] = useState('')

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const [TabData, setTabData] = useState([])
    const [customers, setCustomers] = useState([])

    const goPrev = () => {
        if (page > 0) setPage((prev) => --prev)
    }

    const goNext = () => {
        if (page < totalPages - 1) setPage((prev) => ++prev)
    }

    const cleanModalData = () => {
        setModal(initialModalState)
    }


    const getCustomerData = () => {
        let url = `/search-diamonds/hold/all/data?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
        app_api.get(url)
            .then((res) => (res.data))
            .then((res) => {
                const data = res.data
                const uniqueUsers = new Map();
                data.data.forEach(e => {
                    uniqueUsers.set(e.user.id, e.user);
                });
                setCustomers([...uniqueUsers.values()]);
                setTabData(data.data)
                setCount(data.count)
                setTotalPages(data.count / limit)
                setLoading(false)
                cleanModalData()
            }).catch((err) => {
                setError(err)
                setLoading(true)
            })
    }

    const cleanCatologueData = () => {
        setModal(initialCatalogueState)
    }

    useEffect(() => {
        getCustomerData()
    }, [page, limit, searchFilter])

    const onEditOpen = (id, index) => {
        setModal((prev) => ({
            ...prev,
            type: 'edit',
            id: id,
            index: index,
            state: true,
            data: TabData[index],
        }))
    }

    const onDelete = (id, index) => {
        setModal((prev) => ({
            ...prev,
            id: id,
            index: index,
        }))
        setConfirmationDialog(true)
    }

    const updateToHold = (id) => {
        app_api
            .patch(`/search-diamonds/hold/status/update/${id}`, { status: 'HOLD' })
            .then((res) => {
                toast.success('Diamond detail is hold successfully')
                getCustomerData()
            })
            .catch((err) => {
                setError(err.toString())
                toast.error(err.response.data.message)
            })
    }

    const onDeleteCategory = () => {
        const { id } = modal
        app_api
            .patch(`/search-diamonds/hold/status/update/${id}`, { status: 'UNHOLDED' })
            .then((res) => {
                toast.success('Diamond detail is unholded successfully')
                getCustomerData()
                setConfirmationDialog(false)
            })
            .catch((err) => {
                setError(err.toString())
                toast.error(err.response.data.message)
            })
    }

    const renderModal = () => {
        const { type, state, data, id } = modal
        return (
            <Formik
                initialValues={data}
                validationSchema={null}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    delete values.user
                    setSubmitting(true)
                    app_api
                        .patch(`search-diamonds/${id}`, values)
                        .then((res) => {
                            getCustomerData()
                            toast.success('Successfully updated')
                            setSubmitting(false)
                            cleanCatologueData()
                        }).catch((err) => {
                            setSubmitting(false)
                            toast.error('Failed to update catalogue')
                        })
                }}
            >
                {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setValues,
                    setFieldTouched,
                    values,
                    touched,
                    isSubmitting,
                    errors,
                }) => (
                    <HalfGeneralSlideover
                        title={'Catalogue'}
                        open={state}
                        setOpen={() => {
                            setModal((prev) => ({ ...prev, state: false }))
                        }}
                    >
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="text-left mt-4">
                                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 flex flex-wrap mt-1'>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Customer Name</label>
                                        </div>
                                        <Select
                                            name="user_id"
                                            placeholder='Select Customer... '
                                            options={customers?.map((l) => ({
                                                ...l,
                                                label: l.first_name + ' ' + l.last_name,
                                                value: l.id,
                                            }))}
                                            value={values.user_id
                                                ? {
                                                    value: customers.find((e) => e.id == values.user_id).id,
                                                    label: `${customers.find((e) => e.id == values.user_id).first_name} ${customers.find((e) => e.id == values.user_id).last_name}`
                                                }
                                                : null
                                            }
                                            isDisabled
                                            onChange={(option) => {
                                                setValues((prev) => ({
                                                    ...prev,
                                                    user_id: option.value,
                                                }));
                                            }}
                                            autoComplete="off"
                                            onFocus={() => setFieldTouched('user_id', false)}
                                            onBlur={handleBlur}
                                        />
                                        {touched.user_id && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.user_id}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Hold Type</label>
                                        </div>
                                        <input
                                            name="holdType"
                                            label="holdType"
                                            value={values.holdType}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Hold Type...'
                                            onFocus={() => setFieldTouched('holdType', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.holdType && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.holdType}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Hold Till</label>
                                        </div>
                                        <input
                                            name="holdtill"
                                            label="holdtill"
                                            value={values.holdtill}
                                            autoComplete="off"
                                            disabled
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder='Enter Hold Till...'
                                            onFocus={() => setFieldTouched('holdtill', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.holdtill && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.holdtill}</p>
                                        )}
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex'>
                                            <label className='block text-sm font-medium text-gray-700' >Comment</label>
                                        </div>
                                        <textarea
                                            name="comments"
                                            label="comments"
                                            value={values.comments}
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                                setValues((prev) => ({ ...prev, comments: e.target.value }))
                                            }
                                            placeholder='Enter Comment...'
                                            onFocus={() => setFieldTouched('comments', false)}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {touched.comments && (
                                            <p className="text-red-700 mb-1 error_msg">{errors.comments}</p>
                                        )}
                                    </div>
                                    <div className="overflow-x-auto mt-4 col-span-4">
                                        <table className="min-w-full divide-y divide-gray-200 text-nowrap">
                                            <thead className="bg-gray-50">
                                                <th
                                                    scope="col"
                                                    className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <span>Shape</span>
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <span>Packet Number</span>
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <span>Clarity</span>
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <span>Amount ( $ )</span>
                                                    </div>
                                                </th>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {values?.holdenStocks?.map((item, index) => (
                                                    <>
                                                        <tr key={item}>
                                                            <td className="px-4 py-3 text-sm">
                                                                {item.diamond?.shape}
                                                            </td>
                                                            <td className="px-4 py-3 text-sm">
                                                                {item.diamond.packetNo}
                                                            </td>
                                                            <td className="px-4 py-3 text-sm">
                                                                {item.diamond.clarity}
                                                            </td>
                                                            <td className="px-4 py-3 text-sm">
                                                                ${item?.diamond?.amount?.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <hr className="text-slate-400"></hr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="hidden my-4 sm:mt-6 flex justify-end gap-2 items-center bottom-0">
                                    <div>
                                        <Button
                                            type="button"
                                            className={'bg-red-500 hover:bg-red-700'}
                                            onClick={() => { { setModal((prev) => ({ ...prev, state: false })) } }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div>
                                        <Button type="submit"
                                            disabled={isSubmitting}
                                            className={'bg-green-500 hover:bg-green-700 disabled:bg-gray-300'}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </HalfGeneralSlideover>
                )}
            </Formik>
        )
    }

    return (
        <Sidebar>
            <ConfirmationDialog
                setOpen={setConfirmationDialog}
                open={confirmationDialog}
                onDelete={onDeleteCategory}
                title='Are you sure?'
                description='Do you really want to UNHOLD the entry?'
                confirmationButtonText='Unhold'
            />
            {renderModal()}
            <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900">Hold Diamonds</h1>
                    </div>
                </div>
                {/* <div className="absolute mt-5 w-7/12 flex justify-end items-center text-right right-0 mr-2">
        </div> */}
                <Table
                    progPage={page}
                    totalCount={count}
                    columns={holden_diamonds_columns({ onEditOpen, onDelete,updateToHold })}
                    data={TabData}
                    onEditClose={cleanModalData}
                    setSearchFilter={setSearchFilter}
                />
                <nav
                    className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                >
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing{' '}
                            <select
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                    setPage(0)
                                }}
                                value={limit}
                            >
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>{' '}
                            of <span className="font-medium">{count}</span> results
                        </p>
                    </div>
                    <div className="flex items-center md:justify-end">
                        <span
                            onClick={goPrev}
                            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
                        >
                            Previous
                        </span>
                        <span className="text-sm ml-2">
                            Page{' '}
                            <input
                                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                                type="number"
                                value={page + 1}
                                onChange={(e) => setPage(e.target.value - 1)}
                                max={totalPages}
                                min="1"
                            />
                            <span className='ml-2'></span>/ {Math.ceil(totalPages)}
                        </span>
                        <span
                            onClick={goNext}
                            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
                        >
                            Next
                        </span>
                    </div>
                </nav>
            </div>
            <ToastContainer />
        </Sidebar>
    )
}

export default HoldPage
