import { useState, useEffect, useCallback, useMemo } from 'react'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { customer_column, invoice_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HalfGeneralSlideover from '../components/half-general-slideover'
import CurrencyInput from 'react-currency-input-field'
import Button from '../components/button'
import moment from 'moment'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    email: '',
    contactNo: '',
    userType: '',
  },
}

const InvoicePage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End
  const [inquiredData, setInquiredData] = useState([]);
  const [salestax, setsalestax] = useState(0);
  const [ischeck, setIscheck] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountError, setDiscountError] = useState(false);
  var today = new Date().toISOString().split("T")[0];
  const [customAddError, setCustomAddError] = useState(false);
  const [shippingCharge, setShippingCharge] = useState(0)

  const handleCheckboxdub = () => {
    setIscheck(!ischeck);
  };

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getInquiryData()
  }, [page, limit, searchFilter])


  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onViewOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'view',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    let num = 0;
    if (TabData[index].order.inquiry.user.role == "BUSINESS") {
      TabData[index].order.Order_items.map((e, i) => {
        num += e.catalogue.business_price * e.quantity;
      });
    } else {
      if (TabData[index].order.inquiry.user.role == "INDIVIDUAL") {
        TabData[index].order.Order_items.map((e, i) => {
          num += e.catalogue.individual_price * e.quantity;
        });
      } else {
        TabData[index].order.Order_items.map((e, i) => {
          num += e.catalogue.base_price * e.quantity;
        });
      }
    }
    setTotalAmount(num);
    setIscheck(TabData[index].sameAsBillTo)
    setInquiredData(TabData[index].order.Order_items);
    setDiscount(TabData[index].discount)
    setShippingCharge(TabData[index].shippingCharge)
  }

  const cleanInvoiceModalData = () => {
    setModal(initialModalState)
    setIscheck(false);
    setCustomAddError(false);
  };
  
  const onCancel = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
  }

  const onpayment = (id, index) => {
    app_api
      .patch(`/order/payment/${id}`)
      .then((res) => {
        toast.success('Payment Registered Successfully')
        getInquiryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onPDFOpen = (id, index) => {
    app_api
      .get(`order/getInvoicespdf/${id}`)
      .then((res) => {
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= res.data.data.url;
        a.click();
        // getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onDeleteCategory = () => {
    const { edit_id, index } = modal
    app_api
      .delete(`/order/writeoff/${edit_id}`)
      .then((res) => {
        toast.success('Marked as Write Off Successfully')
        getInquiryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getInquiryData = () => {
    let url = `/order/getInvoices?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
    app_api.get(url)
      .then((res) => (res.data))
      .then((res) => {
        const data = res.data
        setTabData(data.data)
        setCount(data.count)
        setTotalPages(data.count / limit)
        setLoading(false)
        cleanModalData()
      }).catch((err) => {
        setError(err)
        setLoading(true)
      })
  }

  const onWriteoff = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }))
    setConfirmationDialog(true);
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
    <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false)
          let pay = totalAmount + salestax - discount
          app_api
            .post(`order/invoice/${edit_id}`, { ...values, salestax: salestax , sameAsBillto: ischeck, payableamount: pay, discount: discount })
            .then((res) => {
              cleanInvoiceModalData();
              getInquiryData();
              toast.success("Order updated successfully");
              setModal((val) => ({
                ...val,
                state: false
              }))
              setSubmitting(true)
            })
            .catch((err) => {
              toast.error(err.response.data.message);
          });
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={"Invoice Details"}
            open={state}
            setOpen={() => cleanInvoiceModalData()}
          >
            <form onSubmit={handleSubmit} noValidate className='mb-10'>
              <div className="mt-4 text-left">
                <p className="mt-6 font-semibold">Personal Details</p>
                <hr></hr>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                    </div>
                    <input
                      name="name"
                      label="name"
                      value={
                        values?.order?.inquiry?.user?.first_name +
                        " " +
                        (values?.order?.inquiry?.user?.last_name ? values?.order?.inquiry?.user?.last_name : "")
                      }
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter name..."
                      onFocus={() => setFieldTouched("name", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.name && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                    </div>
                    <input
                      name="email"
                      label="email"
                      value={values?.order?.inquiry?.user?.email}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter email..."
                      onFocus={() => setFieldTouched("email", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Customer
                      </label>
                    </div>
                    <input
                      name="userType"
                      label="userType"
                      value={values?.order?.inquiry?.user?.role}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter user type..."
                      onFocus={() => setFieldTouched("userType", false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.userType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.userType}
                      </p>
                    )}
                  </div>
                </div>
                <p className="mt-6 font-semibold">Invoice details</p>
                <hr></hr>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Invoice Date
                      </label>
                    </div>
                    <input
                      name="invoiceDate"
                      type="date"
                      max={today}
                      value={values?.invoice_date}
                      placeholder="Enter student date of birth"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      autoComplete="off"
                      disabled={type == 'view'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.invoiceDate && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.invoiceDate}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Due Date
                      </label>
                    </div>
                    <input
                      name="duedate"
                      type="date"
                      value={moment(values?.duedate).format('YYYY-MM-DD')}
                      placeholder="Enter student date of birth"
                      className="block w-full appearance-none rounded-md border border-gray-300 px-2 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      autoComplete="off"
                      onBlur={handleBlur}
                      disabled={type == 'view'}
                      onChange={handleChange}
                    />
                    {touched.duedate && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.duedate}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="my-2 col-md-12">
                      <label
                        className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                        for="sameAsBillTo"
                      >
                        <input
                          type="checkbox"
                          className="absolute left-0 z-[-1] w-5 h-5 top-0"
                          id="enableAutoFill"
                          checked={ischeck}
                          disabled={type == 'view'}
                          onChange={handleCheckboxdub}
                        />
                        <span className="pl-3 font-normal text-sm text-black cursor-pointer select-none">
                          Same as Bill To
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <p className="mt-6 font-semibold">Order items details</p>
                <hr></hr>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Size</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Color</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Clarity</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Price ( $ )</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Quantity ( CT )</span>
                        </div>
                      </th>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {inquiredData.length > 0 && (
                        <>
                          {inquiredData.map((item, index) => (
                            <>
                              <tr key={item}>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue?.size_name}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.color}
                                </td>
                                <td className="px-4 py-3 text-sm">
                                  {item.catalogue.clarity}
                                </td>
                                {values.userType == "INDIVIDUAL" ? (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.individual_price}
                                  </td>
                                ) : (
                                  <td className="px-4 py-3 text-sm">
                                    ${item.catalogue.business_price}
                                  </td>
                                )}
                                <td className="px-4 py-3 text-sm">
                                  {item.quantity}
                                </td>
                              </tr>
                              <hr className="text-slate-400"></hr>
                            </>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap mt-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Message
                      </label>
                    </div>
                    <textarea
                      name="message"
                      label="description"
                      value={values?.message}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          message: option.target.value,
                        });
                      }}
                      disabled={
                        values?.active != true || values?.status == "DELIVERED"
                      }
                      placeholder="Enter Message..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.message && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full mt-3 flex justify-end">
                  <div className="w-50 bg-gray-200 rounded-md">
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Sub Total:</p>
                      <p>${totalAmount}</p>
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Discount:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={discount}
                        disabled={type == 'view'}
                        maxLength={values?.total_cost?.toString().length}
                        className="w-28 border-2 border-gray-300 bg-inherit appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          // calculateDiscount(value);
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Shipping Charges:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={shippingCharge}
                        disabled={type == 'view'}
                        className="w-28 border-2 border-gray-300 bg-inherit appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          // calculateDiscount(value);
                        }}
                      />
                    </div>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Sale Tax:</p>
                      <p>${salestax}</p>
                    </div>
                    <hr className="mt-2"></hr>
                    <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 my-2">
                      <p>Total Payable:</p>
                      <p>${totalAmount + salestax + shippingCharge - discount}</p>
                    </div>
                  </div>
                </div>
                {discountError && (
                  <>
                    <p className="text-sm text-red-600 text-right">
                      Discount is more then Total Payable Amount
                    </p>
                  </>
                )}

                {/* <div className="my-4 sm:mt-6 flex justify-end gap-2 items-center bottom-0">
                  <div className="flex gap-2 ">
                    <div>
                      <Button
                        type="submit"
                        className={
                          "bg-indigo-600 hover:bg-indigo-800 disabled:bg-gray-300 duration-500"
                        }
                        onClick={() => {
                          setValues({
                            ...values,
                            submitType: "Save",
                          });
                        }}
                        disabled={
                          values?.status == "DELIVERED"
                        }
                      >
                        Create Invoice
                      </Button>
                    </div>
                  </div>
                </div> */}
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        description="Do you really want to mark the invoice as write-off?"
        confirmationButtonText="Write Off"
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Invoice</h1>
          </div>
        </div>
        <Table
          progPage={page}
          totalCount={count}
          columns={invoice_column({onWriteoff, onpayment, onPDFOpen, onViewOpen, onCancel })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
          className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <select
                onChange={(e) => {
                  setLimit(e.target.value)
                  setPage(0)
                }}
                value={limit}
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div className="flex items-center md:justify-end">
            <span
              onClick={goPrev}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
            >
              Previous
            </span>
            <span className="text-sm ml-2">
              Page{' '}
              <input
                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span className="ml-2"></span>/ {Math.ceil(totalPages)}
            </span>
            <span
              onClick={goNext}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
            >
              Next
            </span>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  )
}

export default InvoicePage
