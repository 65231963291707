import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../../assets/icons/logo.png'

export const AccessControl = ({ children }) => {
  const [user, setUser] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    try {
      setUser(JSON.parse(localStorage.getItem('moldKey')))
    } catch (err) {}
  }, [])

  if (user) {
    return children
  } else {
    return navigate('/')
  }
}
