import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import app_api from '../config/api'
import Cookie from "js-cookie";

export const Logout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    LogoutUser()
  }, [])

  const LogoutUser = () => {
    let user = JSON.parse(localStorage.getItem('moldKey'))
    if (user) {
      localStorage.removeItem('moldKey');
      navigate("/")
    }
  }
  
}
