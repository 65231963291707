import React, { useEffect, useState } from 'react'
import app_api from '../config/api'
import Login from '../components/auth/login'
import { Navigate, useNavigate } from 'react-router-dom'
import Cookie from "js-cookie";


const Auth = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [forgotError, setForgotError] = useState(null)
  const [error, setError] = useState(null)
  const [statusCode, setStatusCode] = useState(0)
  const [mfaMesage, setMFAMesage] = useState(null)
  const [authDataURL, setAuthDataURL] = useState()
  const [success, setSuccess] = useState(null)
  const [moldKey, setmoldKey] = useState(localStorage.getItem('moldKey'))

  const login = (values) => {
    setLoading(true)
    app_api
      .post('/auth/login', values)
      .then((res) => {
        if (res.data.data.role === "ADMIN") {
          localStorage.setItem('moldKey', JSON.stringify(res.data.data));
          setLoading(false)
          setError(null)
          navigate('/users')
        } else {
          setLoading(false)
          setError('No User Found')
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'Please enter your correct password')
      })
  }

  const forgotPasssword = (email) => {
    setLoader(true)
    app_api
      .post('/forgot-password', { email: email.forgotEmail })
      .then((res) => {
        setLoader(false)
        setForgotError(null)
        setSuccess('An email has been sent to the registered email address')
      })
      .catch((err) => {
        setLoader(false)
        setForgotError(err?.response?.data?.message || 'Please check your email address')
      })
  }

  const genORCode = (values) => {
    console.log(values)
  }

  return (
    <div>
      <Login
        loading={loading}
        loader={loader}
        authDataURL={authDataURL}
        mfaMesage={mfaMesage}
        statusCode={statusCode}
        forgotError={forgotError}
        error={error}
        login={login}
        genORCode={genORCode}
        forgotPasssword={forgotPasssword}
        success={success}
      />
    </div>
  )
}

export default Auth
