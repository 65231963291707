import React, { useContext, useState } from 'react'
import ToastProvider from '../components/toast/toast_provider'

export const GlobalContext = React.createContext()

export const useGlobalState = () => useContext(GlobalContext)

const Providers = ({ children }) => {
  const [position, setPosition] = useState({
    variant: 'top_right',
  })

  return (
    <GlobalContext.Provider
      value={{
        position: position,
        setPosition: setPosition,
      }}
    >
      <ToastProvider variant={position.variant}>{children}</ToastProvider>
    </GlobalContext.Provider>
  )
}

export default Providers
