import React, {useEffect, useState} from 'react'
import Button from '../components/button'
import { useParams } from 'react-router-dom'
import logo from '../assets/icons/logo.png'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { ResetPasswordSchema } from '../schema'
import app_api from '../config/api'
import moment from 'moment/moment'
import { forgotPasswordSchema } from '../schema'

const ForgotPasssword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [enrol, setEnrol] = useState([])
  const [param, setParam] = useState()
  const params  = useParams()
  let param_id 
  
  const getData = () => {
    app_api
      .get(`/forgot-password/${param}`)
      .then((res) => {
        setEnrol(res.data)
      })
      .catch((err) => {
      })
  }

  useEffect(() => {
    param_id = params.id
    setParam(param_id)
    getData()
  }, [])

  const NavigateTo = () => {
    localStorage.removeItem('moldKey');
    navigate('/')
  }
  
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: '2%',
          marginLeft: '2%',
        }}
      >
        <button
          className="mt-1 ml-1 w-8 h-8"
          onClick={() => {
            NavigateTo()
          }}
          style={{
            backgroundColor: '#3730A3',
            color: 'white',
            borderRadius: '20px',
          }}
          type="button"
        >
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
          </span>
        </button>
      </div>
      <section className="bg-white">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
            style={{
              justifyContent: 'center',
            }}
          >
            <img
              className="mb-10"
              src={logo}
              alt="logo"
              style={{
                width: '30%',
              }}
            />
          </a>
          <div className="w-full p-6 md:mt-0 sm:max-w-md sm:p-8 shadow rounded-md">
            <h2
              className="mb-1 text-5xl font-bold leading-tight tracking-tight md:text-2xl text-center"
              style={{
                fontSize: '40px',
              }}
            >
              Forgot Password
            </h2>
            <Formik
              initialValues={{ newPassword: '', confirmPassword: ''}}
              validationSchema={forgotPasswordSchema}
              onSubmit={(values, {setSubmitting}) =>{
                console.log(param)
                setLoading(true)
                app_api.patch(`/forgot-password/${param}`, values)
                .then((res) => {
                    setLoading(false)
                    setError(null)
                    navigate('/')
                  })
                  .catch((err) => {
                    setLoading(false)
                    setError(err?.response?.data?.message)
                })
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                isValid,
              }) => (
                <form
                  className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
                  onSubmit={handleSubmit}
                  noValidate={true}
                  autoComplete="off"
                >
                <div className='mb-4'>
                  {error !== null && (
                    <p className="text-red-700 error_msg error_text">{error}</p>
                  )}
                </div>
                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-xl font-medium text-gray-900"
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      autoComplete="off"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter New Password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400"
                      required={true}
                    />
                  </div>
                  {touched.newPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.newPassword}
                      </p>
                  )}

                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-xl font-medium text-gray-900"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      autoComplete="off"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter the password again..."
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-400"
                      required={true}
                    />
                  </div>
                  {touched.confirmPassword && (
                      <p className="text-red-700 error_msg">
                        {errors.confirmPassword}
                      </p>
                  )}
                  <Button
                    type="submit"
                    className="text-white mt-5"
                  >
                    Save Password
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      {/* <div classNameName='flex justify-center items-middle w-screen'>
        <div classNameName="text-center">
          <img
            classNameName="h-22  w-48"
            src="https://www.nrichlearning.com.au/wp-content/uploads/2022/09/e-nrich-logo-b.png"
            alt="Your Company"
          />
        </div>
      </div> */}
    </div>
  )
}
export default ForgotPasssword
