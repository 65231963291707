import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import Input from '../components/input'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { inquiry_customer } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HalfGeneralSlideover from '../components/half-general-slideover'
import Select from 'react-select'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    email: '',
    contactNo: '',
    userType: '',
  },
}

const InquiryPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [inquiredData, setInquiredData] = useState([])
  // Pagination End

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const getInquiryData = () => {
    let url = `/inquiry?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
    app_api.get(url)
      .then((res) => (res.data))
      .then((res) => {
        const data = res.data
        setTabData(data.data)
        setCount(data.count)
        setTotalPages(data.count / limit)
        setLoading(false)
        cleanModalData()
      }).catch((err) => {
        setError(err)
        setLoading(true)
      })
  }

  const getCustomerData = () => {
    let url = `/user/getall/data`
    app_api.get(url)
      .then((res) => {
        const data = res.data
        setCategory(data.data)
      }).catch((err) => {
        setError(err)
        setLoading(true)
      })
  }

  useEffect(() => {
    getInquiryData()
  }, [page, limit, searchFilter])


  useEffect(() => {
    getCustomerData()
  }, [])

  const onViewOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'view',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setInquiredData(TabData[index].Inquiry_items)
  }

  const onCancel = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }))
    setConfirmationDialog(true)
  }

  const onDeleteCategory = () => {
    const { edit_id, index } = modal
    console.log(modal)
    app_api
      .delete(`/inquiry/${edit_id}`)
      .then((res) => {
        toast.success('Inquiry Inactive Successfully')
        getInquiryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const moveToOrder = (id) => {
    console.log(id)
  }

  const markAsCancelled = (id) => {
    app_api
      .delete(`/inquiry/${id}`)
      .then((res) => {
        toast.success('Inquiry Inactive Successfully')
        getInquiryData()
        setModal((prev) => ({ ...prev, state: false }))
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    data.customerId = data.user_id
    data.first_name = data.user?.first_name
    data.last_name = data.user?.last_name
    data.active = data.active
    data.email = data?.user?.email
    data.userType = data?.user?.role
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if(values.submitType=='Save'){
            app_api.put(`inquiry/${edit_id}`,{user_id:values.user_id, description:values.description, Inquiry_items:inquiredData})
            .then((res)=>{
              toast.success('Inquiry Updated Successfully')
              setModal((prev) => ({ ...prev, state: false }))
              getInquiryData()
            }).catch((err) => {
              toast.error(err.response.data.message)
            })
          }else{
            let payload ={
              inquiry_id : edit_id,
              description : values.description,
            }
            app_api.post(`order`,payload)
            .then((res)=>{
              cleanModalData()
              setModal((prev) => ({ ...prev, state: false }))
              getInquiryData()
              toast.success('Order booked Successfully')
            }).catch((err) => {
              toast.error(err.response.data.message)
            })
          }
          }
        }
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneralSlideover
            title={'Inquiry Details'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <p className="mt-6 font-semibold">Personal Details</p>
                <hr></hr>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Name</label>
                    </div>
                    <input
                      name="name"
                      label="name"
                      value={values.first_name+' '+ (values.last_name ? values.last_name : '')}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter name...'
                      onFocus={() => setFieldTouched('name', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.name && (
                      <p className="text-red-700 mb-1 error_msg">{errors.name}</p>
                    )}
                  </div>
                  <div className='col-span-2'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Email</label>
                    </div>
                    <input
                      name="email"
                      label="email"
                      value={values.email}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter email...'
                      onFocus={() => setFieldTouched('email', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.email && (
                      <p className="text-red-700 mb-1 error_msg">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className='col-span-4'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Type of Customer</label>
                    </div>
                    <input
                      name="userType"
                      label="userType"
                      value={values.userType}
                      autoComplete="off"
                      disabled={true}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter user type...'
                      onFocus={() => setFieldTouched('userType', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.userType && (
                      <p className="text-red-700 mb-1 error_msg">{errors.userType}</p>
                    )}
                  </div>
                </div>
                <p className="mt-6 font-semibold">Raised Inquiry For</p>
                <hr></hr>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-base font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Size</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-base font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Color</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="group px-4 py-3 text-left text-base font-medium text-gray-900 uppercase tracking-wider"
                      >
                        <div className="flex items-center justify-between">
                          <span>Clarity ( CT )</span>
                        </div>
                      </th>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {inquiredData?.map((s) => (
                        <tr key={s.id}>
                          <td className="px-4 py-3 text-sm">
                            {s.catalogue.size_name}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {s.catalogue.color}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {s.catalogue.clarity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='grid grid-cols-4 gap-4 flex flex-wrap mt-4'>
                  <div className='col-span-4'>
                    <div className='flex'>
                      <label className='block text-sm font-medium text-gray-700' >Note</label>
                    </div>
                    <textarea
                      name="description"
                      label="description"
                      value={values.description}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          description:option.target.value
                        })
                      }}
                      placeholder='Enter Description...'
                      onFocus={() => setFieldTouched('description', false)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.description && (
                      <p className="text-red-700 mb-1 error_msg">{errors.description}</p>
                    )}
                  </div>
                </div>
                </div>
                <div className="my-4 sm:mt-6 flex justify-between gap-2 items-center bottom-0">
                  <div className='flex gap-2'>
                    <div>
                    <Button
                      type="submit"
                      className={'bg-green-700 hover:bg-green-800 disabled:bg-gray-300'}
                      onClick={() =>{
                        setValues({
                          ...values,
                          submitType:'Create order'
                        })
                      }}
                      disabled={values.active != true || values.order_created == true }
                    >
                      Move to Order
                    </Button>
                    </div>
                    <div>
                    <Button
                      type="button"
                      className={'bg-orange-600 hover:bg-orange-500 disabled:bg-gray-300'}
                      onClick={() => { markAsCancelled(values.id)}}
                      disabled={values.active != true || values.order_created == true}
                    >
                      Mark as Cancel
                    </Button>
                    </div>
                  </div>
                  <div className='flex gap-2 '>
                  <div>
                    <Button type="submit"
                    className={'bg-indigo-600 hover:bg-indigo-800 disabled:bg-gray-300 duration-500'}
                    onClick={() =>{
                      setValues({
                        ...values,
                        submitType:'Save'
                      })
                    }}
                    disabled={values.active != true || values.order_created == true}
                    >
                      Update
                    </Button>
                  </div>
                  </div>
                  {/* <div>
                    <Button type="submit"
                      disabled={values.active != true}
                      onClick={() =>{
                        setValues({
                          ...values,
                          submitType:'Create'
                        })
                      }}
                    >
                      Create order
                    </Button>
                  </div> */}
                </div>
              </div>
            </form>
          </HalfGeneralSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
        confirmationButtonText="Cancel"
        description="Do you really want to cancel this inquiry?"
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Inquiry</h1>
          </div>
        </div>
        <Table
          progPage={page}
          totalCount={count}
          columns={inquiry_customer({ onViewOpen, onCancel })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
          className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <select
                onChange={(e) => {
                  setLimit(e.target.value)
                  setPage(0)
                }}
                value={limit}
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div className="flex items-center md:justify-end">
            <span
              onClick={goPrev}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover:text-white duration-500 cursor-pointer"
            >
              Previous
            </span>
            <span className="text-sm ml-2">
              Page{' '}
              <input
                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span className="ml-2"></span>/ {Math.ceil(totalPages)}
            </span>
            <span
              onClick={goNext}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover:text-white duration-500 cursor-pointer"
            >
              Next
            </span>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  )
}

export default InquiryPage
