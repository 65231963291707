import { useState, useEffect, useCallback, useMemo } from 'react'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { kyc_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Field, Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BigSlideover from '../components/big-slideover'
import { Button } from '../components/tables/paginationButtons'
import { KycSchema } from '../schema'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    taxId: "",
    companyName: "",
    tradeName: "",
    businessTel: "",
    faxNo: "",
    companyEmail: "",
    website: "",
    instaGram: "",
    facebook: "",
    sjo: '',
    ijo: '',
    rjo: '',
    cbg: '',
    prime: '',
    lig: '',
    big: '',
    howDid: "TRADE SHOW",
    fromWhere: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    state: "",
    shipaddress1: "",
    shipaddress2: "",
    shipZipCode: "",
    shipCity: "",
    shipState: "",
    noOfYear: "",
    requestCatalogue: '',
    sameAs: '',
    mainFirstName: "",
    mainLastName: "",
    mainPhoneNo: "",
    mainEmail: "",
    isAccount: '',
    AccountFirstName: "",
    AccountLastName: "",
    AccountPhoneNo: "",
    AccountEmail: "",
    isMarketing: '',
    MarketingFirstName: "",
    MarketingLastName: "",
    MarketingPhoneNo: "",
    MarketingEmail: "",
    creditAmount: "",
    JBTNumber: "",
    JBTPhoneNumber: "",
    resaleCertificate: "",
    applicantName: "",
    date: "",
    gurantee: '',
    refCompanyName1: "",
    refCompanyName2: "",
    refCompanyName3: "",
    refPhoneNumber1: "",
    refPhoneNumber2: "",
    refPhoneNumber3: "",
    pointOfContact1: "",
    pointOfContact2: "",
    pointOfContact3: "",
    refEmail1: "",
    refEmail2: "",
    refEmail3: "",
  }
}

const about_us = [
  {
    id: 'TRADE SHOW',
    name: 'TRADE SHOW',
    value: 'TRADE SHOW',
  },
  {
    id: 'WEBSITE',
    name: 'WEBSITE',
    value: 'WEBSITE',
  },
  {
    id: 'SALES REP',
    name: 'SALES REP',
    value: "SALES REP"

  },
  {
    id: 'REFERRAL',
    name: 'REFERRAL',
    value: 'REFERRAL'
  },
  {
    id: 'OTHER',
    name: 'OTHER',
    value: 'OTHER'
  },
]

const KycPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End
  const [todayDate, setTodayDate] = useState("")
  const [selectedValue, setSelectedValue] = useState("");
  const [rciValue, setRCIValue] = useState("")
  const [amlValue, setAMLValue] = useState("Yes")
  const [payload, setPayload] = useState([])

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  useEffect(() => {
    getInquiryData()
  }, [page, limit, searchFilter])

  const cleanModalData = () => {
    setModal(initialModalState)
  }


  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleRCIRadioChange = (e) => {
    setRCIValue(e.target.value)
  }

  const handleAMLRadioChange = (e) => {
    if (e.target.value == "Yes") {
      setAMLValue(true)
    } else {
      setAMLValue(false)
    }
  }

  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setTodayDate(getTodayDate());
  }, []);


  const onViewOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setAMLValue(TabData[index].AML)
    setSelectedValue(TabData[index].typeOfBusiness)
    setRCIValue(TabData[index].RCI)
    setPayload(TabData[index].tradeRef)
  }

  const onDownload = (id) => {
    app_api.get(`kyc/create/pdf/${id}`)
      .then((res) => {
        var  a= document.createElement('a');
        a.target= '_blank';
        console.log(res.data.data)
        a.href= res.data.data.url;
        a.click();
        toast.success('Successfully downloaded the kyc document')
      }).catch((err) => {
        toast.error('Failed to download')
      })
  }

  const onDelete = (id, index) => {
    setModal((prev) => ({
      ...prev,
      edit_id: id,
      index: index,
    }))
    setConfirmationDialog(true)
  }

  const approveKyc = (id) => {
    app_api.get(`user/approvekyc/${id}`)
    .then((res)=>{
      toast.success('Successfully approved kyc')
      getInquiryData()
    }).catch((err) => {
      toast.error('Failed to approve kyc')
    })

  }
  const onDeleteCategory = () => {
    const { edit_id, index } = modal
    app_api
      .delete(`/kyc/${edit_id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getInquiryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getInquiryData = () => {
    let url = `kyc?&size=${limit}&page=${page}&searchFilter=${searchFilter}`
    app_api.get(url)
      .then((res) => (res.data))
      .then((res) => {
        const data = res.data
        setTabData(data.data)
        setCount(data.count)
        setTotalPages(data.count / limit)
        setLoading(false)
        cleanModalData()
      }).catch((err) => {
        setError(err)
        setLoading(true)
      })
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={KycSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
            if (values.sameAs == true) {
              values = { ...values, sameAs: true };
              delete values.shipaddress1;
              delete values.shipaddress2;
              delete values.shipCity;
              delete values.shipZipCode;
              delete values.shipState;
            }
            if (values.isAccount == true) {
              values = { ...values, isAccount: true };
              delete values.AccountFirstName;
              delete values.AccountLastName;
              delete values.AccountEmail;
              delete values.AccountPhoneNo;
            }
            if (values.isMarketing == true) {
              values = { ...values, isMarketing: true };
              delete values.MarketingFirstName;
              delete values.MarketingLastName;
              delete values.MarketingEmail;
              delete values.MarketingPhoneNo;
            }
            if(values.id || values.user){
              delete values.id
              delete values.user
              delete values.refCompanyName
              delete values.pointOfContact
              delete values.refEmail
              delete values.refPhoneNumber
            }
            app_api
                .patch(`kyc/${edit_id}`, {
                  ...values, userId: values.userId,
                  RCI: rciValue, typeOfBusiness: selectedValue, AML: amlValue, date: todayDate, noOfYear: parseInt(values.noOfYear),
                  creditAmount: parseFloat(values.creditAmount),tradeRef:payload
                }).then((res) => {
                  toast.success('Successfully KYC Marked')
                  cleanModalData()
                  getInquiryData()
                }).catch((err) => {
                  toast.error('Failed to KYC Marked')
                })
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <BigSlideover
            title={'KYC Details'}
            open={state}
            setOpen={() => cleanModalData()}
            data={''}
          >
            <form id="mainForm" onSubmit={handleSubmit}>
                  <div className="">
                    <div className="mb-6">
                      <div className="flex flex-wrap mb-4">
                        <div className="max-w-full ">
                          <b>
                            The more information you can provide us, the
                            faster your account will be processed.
                          </b>
                        </div>
                      </div>
                      <div className="flex md:flex-row flex-col gap-8 ">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label>
                            Company Name
                            <span className=" ml-1">*</span>
                          </label>
                          <Field
                            type="text"
                            name="companyName"
                            maxlength="40"
                            className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300 shadow-sm rounded"
                            id="companyName"
                            placeholder="Enter Company Name"
                            onBlur={handleBlur}
                            values={values.companyName}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                companyName: value.target.value
                              }))
                            }}
                          />
                          {touched.companyName && (
                              <p className="text-red-700 error_msg">
                                {errors.companyName}
                              </p>
                            )}
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label for="tradeName" className="">
                            Trade Name (if different from Company Name)
                          </label>
                          <Field
                            type="text"
                            name="tradeName"
                            maxlength="50"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="tradeName"
                            placeholder="Enter Trade Name"
                            onBlur={handleBlur}
                            values={values.tradeName}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                tradeName: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full">
                          <label for="tradeName" >
                            Tax ID
                          </label>
                          <Field
                            type="text"
                            name="tax"
                            maxlength="50"
                            className="text-white border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="tax"
                            placeholder="Enter Tax ID"
                            values={values.taxId}
                            onBlur={handleBlur}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                taxId: value.target.value
                              }))
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="businessTel">
                            Business Telephone{" "}
                            <span className=" ml-1">*</span>
                          </label>
                          <Field
                            type="tel"
                            // pattern="[0-9\/]*"
                            name="businessTel"
                            maxlength="10"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="businessTel"
                            placeholder="Enter Telephone Number"
                            onBlur={handleBlur}
                            values={values.businessTel}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                businessTel: value.target.value
                              }))
                            }}
                          />
                           {touched.businessTel && (
                              <p className="text-red-700 error_msg">
                                {errors.businessTel}
                              </p>
                            )}
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="faxNo">FAX</label>
                          <Field
                            type="tel"
                            name="faxNo"
                            maxlength="10"
                            pattern="[0-9\/]*"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="faxNo"
                            placeholder="Enter FAX Number"
                            onBlur={handleBlur}
                            values={values.faxNo}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                faxNo: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="companyEmail">
                            Company Email Address
                          </label>
                          <Field
                            type="email"
                            maxlength="50"
                            name="companyEmail"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="companyEmail"
                            placeholder="Enter Email Address"
                            onBlur={handleBlur}
                            values={values.companyEmail}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                companyEmail: value.target.value,
                              }));
                            }}
                          />
                          {touched.companyEmail && (
                              <p className="text-red-700 error_msg">
                                {errors.companyEmail}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="website">Website</label>
                          <Field
                            type="text"
                            name="website"
                            maxlength="50"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="website"
                            placeholder="Enter website URL"
                            onBlur={handleBlur}
                            values={values.website}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                website: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="facebook">Facebook</label>
                          <Field
                            type="text"
                            name="facebook"
                            maxlength="256"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="facebook"
                            placeholder="Enter Facebook handle"
                            onBlur={handleBlur}
                            values={values.facebook}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                facebook: value.target.value
                              }))
                            }}
                          />
                        </div>
                        <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/3 w-full ">
                          <label for="instagram">Instagram</label>
                          <Field
                            type="text"
                            name="instagram"
                            maxlength="256"
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            id="instagram"
                            onBlur={handleBlur}
                            placeholder="Enter Instagram handle"
                            values={values.instaGram}
                            onChange={(value) => {
                              setValues((val) => ({
                                ...val,
                                instaGram: value.target.value
                              }))
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row my-3 gap-4 ">
                        <div className="flex flex-col flex-wrap py-2.5 my-3 md:w-1/2 w-full">
                          <label className="">
                            Buying group : Select buying group of which you
                            are a member
                          </label>
                          <div className="mt-2 flex flex-wrap">
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              //
                              for="sjo"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="sjo"
                                name="sjo"
                                values={values.sjo}
                                checked={values.sjo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    sjo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">SJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="ijo"

                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="ijo"
                                name="ijo"
                                values={values.ijo}
                                checked={values.ijo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    ijo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">IJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="rjo"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="rjo"
                                name="rjo"
                                values={values.rjo}
                                checked={values.rjo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    rjo: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">RJO</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="cbg"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="cbg"
                                name="cbg"
                                values={values.cbg}
                                checked={values.cbg}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    cbg: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">CBG</span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="prime"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="prime"
                                name="prime"
                                values={values.prime}
                                checked={values.prime}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    prime: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">
                                PRIME
                              </span>
                            </label>
                            <label
                              className="flex flex-wrap text-center inline-flex!important mr-3 mb-3"
                              for="lig"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="lig"
                                name="lig"
                                values={values.lig}
                                checked={values.lig}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    lig: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">LIG</span>
                            </label>
                            <label
                              className="text-center mr-3 mb-3"
                              for="big"
                            >
                              <input
                                type="checkbox"
                                className="ring-2 ring-blue-500/50 w-5 h-5"
                                id="big"
                                name="big"
                                values={values.big}
                                checked={values.big}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    big: value.target.checked
                                  }))
                                }}
                              />
                              <span className="pl-4 ">BIG</span>
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col py-2.5 my-3 h-11 md:w-1/2 w-full ">
                          <label for="howDid">
                            How did you hear about us?
                          </label>
                          <select
                            className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                            name="howDid"
                            id="howDid"
                            value={values.howDid || ""}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                howDid: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled>Select an option</option>
                            {about_us.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {values.howDid != 'TRADE SHOW' && values.howDid != 'WEBSITE' && (
                        <div className="flex flex-col md:flex-row gap-4">
                          <div className="flex flex-col py-2.5 my-3 h-11 sm:w-1/2 md:w-full w-full"></div>
                          <div className="flex flex-col h-11 sm:w-1/2 md:w-full w-full">
                            <input
                              type="text"
                              placeholder={`Please Specify *`}
                              className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300 shadow-sm rounded"
                              value={values.fromWhere || ""}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  fromWhere: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-6 ">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0 block text-xl font-medium ">
                          <b>Address Information</b>
                        </div>
                        <div className="flex-1 min-h-1 px-3 py-2  pb-4">
                          <div className="md:mb-0 mb-2 px-1 block text-xl font-medium ">
                            Bill To
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full block ">
                              <label for="addressLine1">
                                Address Line 1{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="addressLine1"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="addressLine1"
                                placeholder="Enter Address Line 1"
                                required
    
                                value={values.addressLine1}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    addressLine1: option.target.value,
                                  });
                                }}
                                onBlur={() => setFieldTouched('addressLine1', true)}
                              />
                              {touched.addressLine1 && (
                                <p className="text-red-700 error_msg">
                                  {errors.addressLine1}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full ">
                              <label for="address2">
                                Address Line 2{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="address2"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="address2"
                                placeholder="Enter Address Line 2"
    
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    addressLine2: option.target.value,
                                  });
                                }}
                                value={values.addressLine2}
                              />
                              {/* {touched.address2 && errors.address2 && (
                                <p className="text-red-700 error_msg">
                                  {errors.address2}
                                </p>
                              )} */}
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full ">
                              <label for="zipCode">
                                Zip <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="number"
                                name="zipCode"
                                maxlength="5"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="zipCode"
                                placeholder="Enter Zip"
                                required
                                min="0"
    
                                value={values.zipCode}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    zipCode: option.target.value,
                                  });
                                }}
                              />
                              {touched.zipCode && (
                                <p className="text-red-700 error_msg">
                                  {errors.zipCode}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full ">
                              <label for="city">
                                City{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="city"
                                maxlength="20"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="city"
                                placeholder="Enter City"
                                required
    
                                // onChange={(e) =>
                                //   handleBillToChange("city", e.target.value)
                                // }
                                value={values.city}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    city: option.target.value,
                                  });
                                }}
                              />
                              {touched.city && (
                                <p className="text-red-700 error_msg">
                                  {errors.city}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full ">
                              <label for="state">
                                State{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <select
                                name="state"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="state"
                                required
                                value={values.state}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    state: option.target.value,
                                  });
                                }}>
                                <option value="" selected="selected">
                                  Select a State
                                </option>
                                <option value="ALABAMA">ALABAMA</option>
                                <option value="ALASKA">ALASKA</option>
                                <option value="ARIZONA">ARIZONA</option>
                                <option value="ARKANSAS">ARKANSAS</option>
                                <option value="CALIFORNIA">CALIFORNIA</option>
                                <option value="COLORADO">COLORADO</option>
                                <option value="CONNECTICUT">CONNECTICUT</option>
                                <option value="DELAWARE">DELAWARE</option>
                                <option value="DISTRICT OF COLUMBIA">
                                  DISTRICT OF COLUMBIA
                                </option>
                                <option value="FLORIDA">FLORIDA</option>
                                <option value="GEORGIA">GEORGIA</option>
                                <option value="HAWAII">HAWAII</option>
                                <option value="IDAHO">IDAHO</option>
                                <option value="ILLINOIS">ILLINOIS</option>
                                <option value="INDIANA">INDIANA</option>
                                <option value="IOWA">IOWA</option>
                                <option value="KANSAS">KANSAS</option>
                                <option value="KENTUCKY">KENTUCKY</option>
                                <option value="LOUISIANA">LOUISIANA</option>
                                <option value="MAINE">MAINE</option>
                                <option value="MARYLAND">MARYLAND</option>
                                <option value="MASSACHUSETTS">MASSACHUSETTS</option>
                                <option value="MICHIGAN">MICHIGAN</option>
                                <option value="MINNESOTA">MINNESOTA</option>
                                <option value="MISSISSIPPI">MISSISSIPPI</option>
                                <option value="MISSOURI">MISSOURI</option>
                                <option value="MONTANA">MONTANA</option>
                                <option value="NEBRASKA">NEBRASKA</option>
                                <option value="NEVADA">NEVADA</option>
                                <option value="NEW HAMPSHIRE">NEW HAMPSHIRE</option>
                                <option value="NEW JERSEY">NEW JERSEY</option>
                                <option value="NEW MEXICO">NEW MEXICO</option>
                                <option value="NEW YORK">NEW YORK</option>
                                <option value="NORTH CAROLINA">NORTH CAROLINA</option>
                                <option value="NORTH DAKOTA">NORTH DAKOTA</option>
                                <option value="OHIO">OHIO</option>
                                <option value="OKLAHOMA">OKLAHOMA</option>
                                <option value="OREGON">OREGON</option>
                                <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                                <option value="PUERTO RICO">PUERTO RICO</option>
                                <option value="RHODE ISLAND">RHODE ISLAND</option>
                                <option value="SOUTH CAROLINA">SOUTH CAROLINA</option>
                                <option value="SOUTH DAKOTA">SOUTH DAKOTA</option>
                                <option value="TENNESSEE">TENNESSEE</option>
                                <option value="TEXAS">TEXAS</option>
                                <option value="UTAH">UTAH</option>
                                <option value="VERMONT">VERMONT</option>
                                <option value="VIRGINIA">VIRGINIA</option>
                                <option value="WASHINGTON">WASHINGTON</option>
                                <option value="WEST VIRGINIA">WEST VIRGINIA</option>
                                <option value="WISCONSIN">WISCONSIN</option>
                                <option value="WYOMING">WYOMING</option>
                              </select>
                              {touched.state && (
                                <p className="text-red-700 error_msg">
                                  {errors.state}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="my-2 col-md-12 px-1 pt-4 block text-xl font-medium ">
                            Ship To <small>(PO Boxes not allowed)</small>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="my-2 col-md-12">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                                for="sameAsBillTo"
                              >
                                <input
                                  type="checkbox"
                                  className="absolute left-0 z-[-1] w-6 h-6 top-0"
                                  value={values.sameAs}
                                  checked={values.sameAs}
                                  onChange={(same) => {
                                    setValues((sameval) => ({
                                      ...sameval,
                                      sameAs: same.target.checked
                                    }))
                                  }}
                                  disabled={values.addressLine1 != null && values.addressLine2 != null && values.zipCode != null && values.city != null && values.state != null ? false : true}
                                />
                                <span className="pl-3 font-normal  cursor-pointer select-none">
                                  Same as Bill To
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full block ">
                              <label for="addressLine1">
                                Address Line 1{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="shipaddress1"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="shipaddress1"
                                placeholder="Enter Address Line 1"
                                required
                                value={values.sameAs?values.addressLine1:values.shipaddress1}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    shipaddress1: option.target.value,
                                  });
                                }}
                              disabled={values.sameAs ? true : false}
                                onBlur={() => setFieldTouched('addressLine1', true)}
                              />
                              {values.sameAs != true ?touched.shipaddress1 && (
                                <p className="text-red-700 error_msg">
                                  {errors.shipaddress1}
                                </p>
                              ):null}
                            </div>
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full ">
                              <label for="shipaddress2">
                                Address Line 2{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="shipaddress2"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="shipaddress2"
                                placeholder="Enter Address Line 2"
    
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    shipaddress2: option.target.value,
                                  });
                                }}
                              disabled={values.sameAs ? true : false}
                                value={values.sameAs?values.addressLine2:values.shipaddress2}
                              />
                              {/* {touched.address2 && errors.address2 && (
                                <p className="text-red-700 error_msg">
                                  {errors.address2}
                                </p>
                              )} */}
                            </div>
                          </div>

                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col py-2.5 my-6 h-11 md:w-1/2 w-full ">
                              <label for="shipZipCode">
                                Zip <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="number"
                                name="shipZipCode"
                                maxlength="5"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="shipZipCode"
                                placeholder="Enter Zip"
                                required
                                min="0"
                              disabled={values.sameAs ? true : false}
                                value={values.sameAs?values.zipCode:values.shipZipCode}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    shipZipCode: option.target.value,
                                  });
                                }}
                              />
                              {values.sameAs != true ?touched.shipZipCode && (
                                <p className="text-red-700 error_msg">
                                  {errors.shipZipCode}
                                </p>
                              ):null}
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full ">
                              <label for="city">
                                City{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="shipCity"
                                maxlength="20"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="shipCity"
                                placeholder="Enter shipCity"
                                disabled={values.sameAs ? true : false}
                                value={values.sameAs?values.city:values.shipCity}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    shipCity: option.target.value,
                                  });
                                }}
                              />
                              {values.sameAs != true ?touched.shipCity && (
                                <p className="text-red-700 error_msg">
                                  {errors.shipCity}
                                </p>
                              ):null}
                            </div>
                            <div className="flex flex-col py-2.5  my-6 h-11 md:w-1/2 w-full ">
                              <label for="shipState">
                                State{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <select
                                name="shipState"
                                className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="shipState"
                                required
                                value={values.sameAs?values.state:values.shipState}
                              disabled={values.sameAs ? true : false}
                                onChange={(option) => {
                                  setValues({
                                    ...values,
                                    shipState: option.target.value,
                                  });
                                }}>
                                <option value="" selected="selected">
                                  Select a State
                                </option>
                                <option value="ALABAMA">ALABAMA</option>
                                <option value="ALASKA">ALASKA</option>
                                <option value="ARIZONA">ARIZONA</option>
                                <option value="ARKANSAS">ARKANSAS</option>
                                <option value="CALIFORNIA">CALIFORNIA</option>
                                <option value="COLORADO">COLORADO</option>
                                <option value="CONNECTICUT">CONNECTICUT</option>
                                <option value="DELAWARE">DELAWARE</option>
                                <option value="DISTRICT OF COLUMBIA">
                                  DISTRICT OF COLUMBIA
                                </option>
                                <option value="FLORIDA">FLORIDA</option>
                                <option value="GEORGIA">GEORGIA</option>
                                <option value="HAWAII">HAWAII</option>
                                <option value="IDAHO">IDAHO</option>
                                <option value="ILLINOIS">ILLINOIS</option>
                                <option value="INDIANA">INDIANA</option>
                                <option value="IOWA">IOWA</option>
                                <option value="KANSAS">KANSAS</option>
                                <option value="KENTUCKY">KENTUCKY</option>
                                <option value="LOUISIANA">LOUISIANA</option>
                                <option value="MAINE">MAINE</option>
                                <option value="MARYLAND">MARYLAND</option>
                                <option value="MASSACHUSETTS">MASSACHUSETTS</option>
                                <option value="MICHIGAN">MICHIGAN</option>
                                <option value="MINNESOTA">MINNESOTA</option>
                                <option value="MISSISSIPPI">MISSISSIPPI</option>
                                <option value="MISSOURI">MISSOURI</option>
                                <option value="MONTANA">MONTANA</option>
                                <option value="NEBRASKA">NEBRASKA</option>
                                <option value="NEVADA">NEVADA</option>
                                <option value="NEW HAMPSHIRE">NEW HAMPSHIRE</option>
                                <option value="NEW JERSEY">NEW JERSEY</option>
                                <option value="NEW MEXICO">NEW MEXICO</option>
                                <option value="NEW YORK">NEW YORK</option>
                                <option value="NORTH CAROLINA">NORTH CAROLINA</option>
                                <option value="NORTH DAKOTA">NORTH DAKOTA</option>
                                <option value="OHIO">OHIO</option>
                                <option value="OKLAHOMA">OKLAHOMA</option>
                                <option value="OREGON">OREGON</option>
                                <option value="PENNSYLVANIA">PENNSYLVANIA</option>
                                <option value="PUERTO RICO">PUERTO RICO</option>
                                <option value="RHODE ISLAND">RHODE ISLAND</option>
                                <option value="SOUTH CAROLINA">SOUTH CAROLINA</option>
                                <option value="SOUTH DAKOTA">SOUTH DAKOTA</option>
                                <option value="TENNESSEE">TENNESSEE</option>
                                <option value="TEXAS">TEXAS</option>
                                <option value="UTAH">UTAH</option>
                                <option value="VERMONT">VERMONT</option>
                                <option value="VIRGINIA">VIRGINIA</option>
                                <option value="WASHINGTON">WASHINGTON</option>
                                <option value="WEST VIRGINIA">WEST VIRGINIA</option>
                                <option value="WISCONSIN">WISCONSIN</option>
                                <option value="WYOMING">WYOMING</option>
                              </select>
                              {values.sameAs != true ?touched.shipState && (
                                <p className="text-red-700 error_msg">
                                  {errors.shipState}
                                </p>
                              ):null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-6">
                      <div className="flex flex-wrap">
                        <div className="col-md-3 py-2">
                          <label
                            className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact"
                            for="requestCatalog"
                          >
                            <input
                              type="checkbox"
                              className="absolute left-0 z-[-1] w-6 h-6  top-0"
                              id="requestCatalog"
                              name="requestCatalog"
                              value={values.requestCatalogue}
                              checked={values.requestCatalogue}
                              onChange={(same) => {
                                setValues((sameval) => ({
                                  ...sameval,
                                  requestCatalogue: same.target.checked
                                }))
                              }}
                            />
                            <span
                              className="pl-3 cursor-pointer select-none"
                            >
                              Request Catalog
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0 ">
                          <b>Contact Information</b>
                        </div>
                        <div className="p-3 mb-0 ">
                          Please note, the Main Contact’s email will be the
                          primary email used to create a web login to our
                          website, as well as where order tracking information
                          will be sent. You will receive a confirmation at
                          that email once your account is approved.
                        </div>
                        <div className="flex-1 min-h-1 p-3">
                          <div className="flex flex-col lg:flex-row mb-6 gap-4 ">
                            <div className="  mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none md:w-1/6 w-full max-w-full pt-4">
                              Main contact
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mcfirstname">
                                First Name
                                <span className=" ml-1">*</span>
                              </label>

                              <Field
                                type="text"
                                name="mcfirstname"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mcfirstname"
                                placeholder="First Name"
                            onBlur={handleBlur}
                                value={values.mainFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainFirstName: value.target.value
                                  }));
                                }}
                              />
                               {touched.mainFirstName && errors.mainFirstName && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainFirstName}
                                </p>
                              )}
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mclastname">
                                Last Name
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                name="mclastname"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mclastname"
                                placeholder="Last Name"
                                onBlur={handleBlur}    
                                value={values.mainLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainLastName: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainLastName && errors.mainLastName && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainLastName}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mcphonenumber">
                                Phone number
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="tel"
                                name="mcphonenumber"
                                maxlength="10"
                                pattern="[0-9\/]*"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mcphonenumber"
                                placeholder="Phone Number"
                                onBlur={handleBlur}
                                value={values.mainPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainPhoneNo: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainPhoneNo && errors.mainPhoneNo && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainPhoneNo}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mcemail">
                                Email
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="mainEmail"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="mainEmail"
                                placeholder="Enter Email"
                                onBlur={handleBlur}
                                value={values.mainEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    mainEmail: value.target.value
                                  }));
                                }}
                              />
                              {touched.mainEmail && errors.mainEmail && (
                                <p className="text-red-700 error_msg">
                                  {errors.mainEmail}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row mb-6 gap-4">
                            <div className="mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none md:w-1/6 w-full max-w-full pt-4">
                              <div classNameName="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                                <p className="">
                                  Accounts payable
                                </p>
                              </div>
                              <div className="flex flex-col mb-4 md:w-full w-1/6 max-w-full">
                                <label
                                  className="custom-control custom-checkbox"
                                  for="mainContactCopyAP"
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-Field"
                                    id="mainContactCopyAP"
                                    name="mainContactCopyAP"
                                    value={values.isAccount}
                                    checked={values.isAccount}
                                    onChange={(eve) => {
                                      setValues((val) => ({
                                        ...val,
                                        isAccount: eve.target.checked
                                      }))
                                    }}
                                    disabled={values.mainFirstName && values.mainLastName && values.mainEmail && values.mainPhoneNo ? false : true}
                                  />
                                  <span className="pl-3 cursor-pointer select-none ">
                                    Same as Main Contact
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="apfirstname">First Name</label>
                              <Field
                                type="text"
                                name="apfirstname"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="apfirstname"
                                placeholder="First Name"
                                value={values.isAccount === true ? values.mainFirstName : values.AccountFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountFirstName: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="aplastname">Last Name</label>
                              <Field
                                type="text"
                                name="aplastname"
                                maxlength="40"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="aplastname"
                                placeholder="Last Name"
                                value={values.isAccount === true ? values.mainLastName : values.AccountLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountLastName: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="apphonenumber">Phone number</label>
                              <Field
                                type="tel"
                                name="apphonenumber"
                                maxlength="10"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                pattern="[0-9\/]*"
                                id="apphonenumber"
                                placeholder="Phone Number"
                                value={values.isAccount === true ? values.mainPhoneNo : values.AccountPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountPhoneNo: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="apemail">Email</label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="apemail"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="apemail"
                                placeholder="Enter Email"
                                value={values.isAccount === true ? values.mainEmail : values.AccountEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    AccountEmail: value.target.value
                                  }))
                                }}
                                disabled={values.isAccount ? true : false}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row mb-6 gap-4">
                            <div className="mb-4 col-md-3 pt-3 small-checkbox mb-4 flex-none w-1/6 max-w-full pt-4">
                              <div classNameName="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/5 max-w-full ">
                                <p className="">
                                  Marketing manager
                                </p>
                              </div>
                              <label
                                className="custom-control custom-checkbox"
                                for="mainContactCopyMM"
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-Field"
                                  id="mainContactCopyMM"
                                  name="mainContactCopyMM"
                                  value={values.isMarketing}
                                  checked={values.isMarketing}
                                  onChange={(eve) => {
                                    setValues((val) => ({
                                      ...val,
                                      isMarketing: eve.target.checked
                                    }))
                                  }}
                                  disabled={values.mainFirstName && values.mainLastName && values.mainEmail && values.mainPhoneNo ? false : true}
                                />
                                <span className="pl-3 cursor-pointer select-none ">
                                  Same as Main Contact
                                </span>
                              </label>
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mmpfirstname">First Name</label>
                              <Field
                                type="text"
                                maxlength="40"
                                name="mmpfirstname"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="mmpfirstname"
                                placeholder="First Name"
                                value={values.isMarketing === true ? values.mainFirstName : values.MarketingFirstName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingFirstName: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>

                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mmplastname">Last Name</label>
                              <Field
                                type="text"
                                maxlength="40"
                                name="mmplastname"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="mmplastname"
                                placeholder="Last Name"
                                value={values.isMarketing === true ? values.mainLastName : values.MarketingLastName}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingLastName: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mmphonenumber">Phone number</label>
                              <Field
                                type="tel"
                                maxlength="10"
                                name="mmphonenumber"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                pattern="[0-9\/]*"
                                id="mmphonenumber"
                                placeholder="Phone Number"
                                value={values.isMarketing === true ? values.mainPhoneNo : values.MarketingPhoneNo}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingPhoneNo: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                            <div className="flex flex-col mb-4 w-full sm:w-full md:w-full lg:w-1/6 max-w-full ">
                              <label for="mmemail">Email</label>
                              <Field
                                type="email"
                                maxlength="50"
                                name="mmemail"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded disabled:bg-gray-300"
                                id="mmemail"
                                placeholder="Enter Email"
                                value={values.isMarketing === true ? values.mainEmail : values.MarketingEmail}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    MarketingEmail: value.target.value
                                  }))
                                }}
                                disabled={values.isMarketing ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0">
                          <b className="">Type of Business</b>
                        </div>
                        <div className="flex-1 min-h-1 p-2">
                          <div className="flex flex-wrap  mx-4  gap-4 min-h-1">
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="wholesaler"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="WHOLESALER"
                                  value="WHOLESALER"
                                  // checked={true}
                                  checked={selectedValue === "WHOLESALER"} // Check if the value matches the selectedValue
                                  onChange={handleRadioChange} // Call the event handler on change
                                />
                                <span className="pl-3 cursor-pointer select-none ">
                                  Wholesaler
                                </span>
                              </label>
                            </div>
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="chainstore"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="chainstore"
                                  value="chain_store"
                                  checked={selectedValue === "chain_store"}
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Chain Store
                                </span>
                              </label>
                            </div>
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="manufacturer"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="manufacturer"
                                  value="manufacturer"
                                  checked={selectedValue === "manufacturer"}
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Manufacturer
                                </span>
                              </label>
                            </div>
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="departmentstore"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="departmentstore"
                                  value="departmentstore"
                                  checked={
                                    selectedValue === "departmentstore"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Department Store
                                </span>
                              </label>
                            </div>
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="internetcompany"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="internetcompany"
                                  value="internetcompany"
                                  checked={
                                    selectedValue === "internetcompany"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Internet Company
                                </span>
                              </label>
                            </div>
                            <div className="py-2">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="independentjeweler"
                              >
                                <Field
                                  type="radio"
                                  className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                                  name="typeOfBusiness"
                                  id="independentjeweler"
                                  value="independentjeweler"
                                  checked={
                                    selectedValue === "independentjeweler"
                                  }
                                  onChange={handleRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Independent Jeweler
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="mb-4 flex-none w-full md:w-1/2 max-w-full mt-3">
                            <div className="mb-4 col-md-6 ">
                              <label for="noOfYear">
                                Number of years in business
                              </label>
                              <Field
                                type="number"
                                name="noOfYear"
                                className="  block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                                id="noOfYear"
                                placeholder="Enter Number of years"
                                maxlength="3"
                                min="0"
                                values={values.noOfYear?values.noOfYear:null}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    noOfYear: value.target.value
                                  }))
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 mb-2"></div>
                    <div className="mb-6">
                      <div className="relative flex flex-col min-w-0 break-words bg-clip-border-box border border-solid border-gray-300 rounded-sm">
                        <div className="p-3 mb-0 ">
                          <b>
                            How would you like to do business with RCI?{" "}
                            <span className=" ml-1">*</span>
                          </b>
                        </div>
                        <div className="flex-1 min-h-1 p-4">
                          <div className="flex flex-wrap -mx-4  gap-4 flex-1 min-h-1 ">
                            <div className="col-sm-3">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="creditTerms"
                              >
                                <Field
                                  type="radio"
                                  id="creditTerms"
                                  name="rci_radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  value="Credit Terms"
                                  checked={rciValue === "Credit Terms"}
                                  onChange={handleRCIRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none ">
                                  Credit Terms
                                </span>
                              </label>
                            </div>
                            <div className="col-sm-3">
                              <label
                                className="inline-flex items-center flex-wrap relative z-10 block min-h-6 pl-6 print-color-adjust-exact custom-radio"
                                for="creditCard"
                              >
                                <Field
                                  type="radio"
                                  id="creditCard"
                                  name="rci_radio"
                                  className="accent-regal-red absolute  left-0  z-[-1] w-6 h-6"
                                  value="Credit Card"
                                  checked={rciValue === "Credit Card"}
                                  onChange={handleRCIRadioChange}
                                />
                                <span className="pl-3  cursor-pointer select-none">
                                  Credit Card
                                </span>
                              </label>
                            </div>
                          </div>

                          {/* <div
                            className="flex flex-col mb-6 gap-4 jbt-row credit_card"
                            // style="display: none;"
                          ></div> */}

                          {rciValue != 'Credit Card' && (<>
                            <div className="flex flex-col mb-6 gap-4 gap-4">
                              <div className="flex flex-col py-2.5 my-3 h-11 md:w-1/3 w-full  col-md-4 mb-2">
                                <label for="jbtcreditamount">
                                  Credit amount{" "}
                                </label>
                                <Field
                                  type="number"
                                  name="jbtcreditamount"
                                  className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                  id="jbtcreditamount"
                                  placeholder="Enter requested credit amount"
                                  text="number"
                                  maxlength="8"
                                  min="0"
                                  // checked={selectedValue === "credit_amount"}
                                  // onChange={handleRadioChange}
                                  values={values.creditAmount}
                                  onChange={(value) => {
                                    setValues((val) => ({
                                      ...val,
                                      creditAmount: value.target.value,
                                    }));
                                  }}
                                />
                                {/* {touched.jbtcreditamount &&
                                errors.jbtcreditamount && (
                                  <p className="text-red-700 error_msg">
                                    {errors.jbtcreditamount}
                                  </p>
                                )} */}
                              </div>

                              <div className="flex flex-col md:flex-row gap-4 my-6">
                                <div className="flex flex-col py-2.5  my-3 h-11  md:w-1/3 w-full  col-md-4 mb-2">
                                  <label for="JBTNumber">JBT Number</label>
                                  <Field
                                    type="number"
                                    name="JBTNumber"
                                    maxlength="10"
                                    pattern="[0-9\/]*"
                                    className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                    id="JBTNumber"
                                    placeholder="Enter JBT Number"
                                    min="0"
                                    values={values.JBTNumber}
                                    required
                                    onChange={(value) => {
                                      setValues((val) => ({
                                        ...val,
                                        JBTNumber: value.target.value
                                      }))
                                    }}
                                  />
                                </div>

                                <div className="flex flex-col py-2.5  my-3 h-11  md:w-1/3 w-full  col-md-4 mb-2">
                                  <label for="jbtphonenumber">
                                    JBT Phone number
                                  </label>
                                  <Field
                                    type="tel"
                                    name="jbtphonenumber"
                                    maxlength="10"
                                    className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                    pattern="[0-9\/]*"
                                    id="jbtphonenumber"
                                    placeholder="Phone Number listed in JBT"
                                    values={values.JBTPhoneNumber}
                                    onChange={(value) => {
                                      setValues((val) => ({
                                        ...val,
                                        JBTPhoneNumber: value.target.value,
                                      }));
                                    }}
                                  />
                                  {/* {touched.jbtphonenumber &&
                                  errors.jbtphonenumber && (
                                    <p className="text-red-700 error_msg">
                                      {errors.jbtphonenumber}
                                    </p>
                                  )} */}
                                </div>
                              </div>

                              <div className="mb-4 w-full col-md-8">
                                <div className="p-3 mb-0 px-2 text-lg ">
                                  Trade References (We highly recommend adding
                                  at least 3 references)
                                </div>
                                <div className="flex-1 min-h-1 px-2">
                                  <p className="pt-4 text-lg">First Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />

                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refCompanyName1"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName1"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName1"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName1: value.target.value
                                          }))
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      {touched.refCompanyName1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName1}
                                        </p>
                                      )}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="pointOfContact1">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact1"
                                        maxlength="50"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact1"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact1}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refEmail1">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail1"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail1"
                                        placeholder="Enter company email"
                                        values={values.refEmail1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail1}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refPhoneNumber1">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber1"
                                        maxlength="10"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber1"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber1}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber1: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber1 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber1}
                                        </p>)}
                                    </div>
                                  </div>

                                  <p className="pt-4 text-lg">Second Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />
                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refCompanyName2"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName2"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName2"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName2: value.target.value
                                          }))
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      {touched.refCompanyName2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName2}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="pointOfContact3">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact2"
                                        maxlength="50"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact2"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact2}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refEmail2">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail2"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail2"
                                        placeholder="Enter company email"
                                        values={values.refEmail2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail2}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refPhoneNumber2">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber2"
                                        maxlength="10"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber2"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber2}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber2: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber2 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber2}
                                        </p>)}
                                    </div>
                                  </div>
                                  <p className="pt-4 text-lg">Third Reference</p>
                                  <hr className="text-gray-50 bg-gray-50" />

                                  <div className="flex flex-col md:flex-row gap-4">
                                    {/* <div className="mb-4 col-md-6"> */}
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refCompanyName3"
                                      >
                                        Company Name
                                      </label>
                                      <Field
                                        type="text"
                                        maxlength="50"
                                        name="refCompanyName3"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refCompanyName3"
                                        placeholder="Enter Name"
                                        values={values.refCompanyName3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refCompanyName3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refCompanyName3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refCompanyName3}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="pointOfContact3">
                                        Person to Contact{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pointOfContact3"
                                        maxlength="50"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="pointOfContact3"
                                        placeholder="Enter Name"
                                        values={values.pointOfContact3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            pointOfContact3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.pointOfContact3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.pointOfContact3}
                                        </p>)}
                                    </div>
                                  </div>
                                  <div className="flex flex-col md:flex-row  my-6 gap-4">
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refEmail3">Email</label>
                                      <Field
                                        type="email"
                                        maxlength="50"
                                        name="refEmail3"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refEmail3"
                                        placeholder="Enter company email"
                                        values={values.refEmail3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refEmail3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refEmail3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refEmail3}
                                        </p>)}
                                    </div>
                                    <div className="flex flex-col py-2.5  my-6 h-11  md:w-1/3 w-full col-md-4 mb-2">
                                      <label for="refPhoneNumber3">
                                        Phone Number
                                      </label>
                                      <Field
                                        type="tel"
                                        name="refPhoneNumber3"
                                        maxlength="10"
                                        className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                        id="refPhoneNumber3"
                                        placeholder="Phone Number"
                                        values={values.refPhoneNumber3}
                                        onChange={(value) => {
                                          setValues((val) => ({
                                            ...val,
                                            refPhoneNumber3: value.target.value
                                          }))
                                        }}
                                      />
                                      {touched.refPhoneNumber3 && (
                                        <p className="text-red-700 error_msg">
                                          {errors.refPhoneNumber3}
                                        </p>)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>)}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 align-items-center mb-2">
                      <div className="flex flex-col py-2.5 md:my-6 my-3 h-11 md:w-1/3 w-full max-w-1/3">
                        <label
                          for="resalecertificate"
                          className=" text-base mb-0 "
                        >
                          Resale certificate #/Tax Exempt #{" "}
                          <span className=" ml-1">*</span>
                        </label>
                      </div>
                      <div className="px-4 mb-4  md:my-6 my-3 flex-none md:w-2/3 w-full max-w-full mb-4">
                        <Field
                          type="text"
                          name="resaleCertificate"
                          // className="border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                          className="  block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                          id="resaleCertificate"
                          placeholder="Enter"
                          maxlength="50"
                          values={values.resaleCertificate}
                          onChange={(value) => {
                            setValues((val) => ({
                              ...val,
                              resaleCertificate: value.target.value
                            }))
                          }}
                        />
                        {touched.resaleCertificate &&(
                            <p className="text-red-700 error_msg">
                              {errors.resaleCertificate}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row mb-3 gap-4">
                      <div className="mb-4 w-full max-w-full  my-2">
                        <p>
                          Is your company AML (Anti Money Laundering)
                          Complaint pursuant to the USA Patriot Act *
                        </p>
                        <div>
                          <label
                            // className="custom-control custom-radio d-flex mb-3 w-100"
                            className="flex flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2"
                            for="amlprogramyes"
                          >
                            <Field
                              type="radio"
                              id="amlprogramyes"
                              name="amlprogram"
                              className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                              value="Yes"
                              checked={amlValue == true}
                              onChange={handleAMLRadioChange}
                            // checked=""
                            />
                            <span className="pl-3 text-gray-600 cursor-pointer select-none order-0 ">
                              Yes
                            </span>
                            <p className="pl-4 amlprogramtextyes mb-0 order-1 ">
                              We have or will be installing an AML Program
                            </p>
                          </label>

                          <label
                            className="flex flex-wrap block relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2"
                            for="amlprogramno"
                          >
                            <Field
                              type="radio"
                              id="amlprogramno"
                              name="amlprogram"
                              className="accent-regal-red absolute left-0 z-[-1] w-6 h-6"
                              value="No"
                              checked={amlValue == false}
                              onChange={handleAMLRadioChange}
                            />
                            <span className="pl-3 text-gray-600 cursor-pointer select-none order-0 ">
                              No
                            </span>
                            <p className="pl-4 amlprogramtextno mb-0 order-1 ">
                              We will not institute an AML program due to our
                              status as an ‘exempt’ retailer
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4  border-white border border-solid">
                      <div className="flex flex-col min-w-0 break-words bg-clip-border-box rounded-sm">
                        <div className="flex-1 min-h-1 mt-3">
                          <div className="flex flex-wrap">
                            <div className="bg-transparent border-0 text-18 text-black pb-0 mb-10 ">
                              <b className="">
                                Digital signature
                              </b>
                            </div>
                            <div className="px-2 mb-4 relative flex-none w-full max-w-full">
                              <label className=" text-sm mb-1 inline-block" >
                                Comments
                              </label>
                              <textarea
                                name="comments"
                                maxlength="1000"
                                className="  shadow-md block w-full h-[calc(1.5em + 0.75rem + 2px)] p-3 text-base font-normal leading-1.5  bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out"
                                id="comments"
                                rows="3"
                                values={values.comments}
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    comments: value.target.value
                                  }))
                                }}
                              >{values.comments}</textarea>
                              <label className="float-right ">
                                <span id="commentCount">{values.comments!='' && values.comments!=null?1000-values.comments?.length:1000}</span> /1000
                                characters remaining{" "}
                              </label>
                            </div>
                          </div>
                          <div className="accept-row">
                            <label
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              htmlFor="acknowledgement"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="acknowledgement"
                                name="acknowledgement"
                                // value="true"
                                required
                                checked
                              />
                              <span className="pl-3  cursor-pointer select-none">
                                The information provided above is accurate to
                                the best of my knowledge.{" "}
                                <span className=" ml-1">*</span>
                              </span>
                            </label>
                            <label
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              htmlFor="guarantees"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="guarantees"
                                name="guarantees"
                                value={values.gurantee}
                                checked={values.gurantee}
                                onChange={(same) => {
                                  setValues((sameval) => ({
                                    ...sameval,
                                    gurantee: same.target.checked
                                  }))
                                }}

                              />
                              <span className="pl-3  cursor-pointer select-none">
                                GUARANTEE: In order to induce Royal Chain
                                INC., (Hereinafter "RCI"). To sell merchandise
                                and extend credit to your company the
                                undersigned personally guarantees the prompt
                                payment of any, and all indebtedness which may
                                be incurred by the applicant to RCI, and in
                                the event of any default at any time by the
                                applicant, RCI shall be entitled to look to
                                the undersigned guarantor(s) immediately for
                                such payment without prior demand or notice.
                              </span>
                            </label>
                            <label
                              className="flex relative z-10 min-h-6 pl-6 print-color-adjust-exact my-2 mb-4"
                              for="acknowledgement2"
                            >
                              <input
                                type="checkbox"
                                className="pl-3 text-gray-500 cursor-pointer select-none relative mb-0 align-top absolute left-0 z-[-1] h-6"
                                id="acknowledgement2"
                                name="acknowledgement2"
                                required
                                checked
                              />
                              <span className="pl-3  cursor-pointer select-none">
                                In the event this account must be referred to
                                an attorney or any agency For collection any
                                indebtedness owed by the guarantor or the
                                applicant to RCI the guarantor agrees to pay
                                all reasonable costs thereof including
                                collection Fees, reasonable attorney Fees,
                                court costs and expenses incurred in
                                connection therewith.{" "}
                                <span className=" ml-1">*</span>
                              </span>
                            </label>
                          </div>
                          <div className="flex flex-col sm:flex-row mb-6 gap-4 pt-2 gap-12">
                            <div className="flex flex-col py-2.5  h-11 md:w-2/3 w-full  col-md-4 mb-4 max-w-full">
                              <label for="applicantName">
                                Applicant Name{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                type="text"
                                maxlength="50"
                                name="applicantName"
                                className="  border-solid py-2 px-1 border-2 border-gray-200 hover:border-blue-300 focus:border-blue-300  shadow-sm rounded"
                                id="applicantName"
                                placeholder="Enter full name"
                                values={values.applicantName}
                                required
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    applicantName: value.target.value
                                  }))
                                }}
                              />
                              {touched.applicantName && (
                                <p className="text-red-700 error_msg">
                                  {errors.applicantName}
                                </p>
                              )}
                            </div>
                            <div className="mb-4 col-md-1"></div>
                            <div className="flex flex-col py-2.5   h-11 md:w-1/3 w-full  mb-4 max-w-full">
                              <label for="applicantdate">
                                Date{" "}
                                <span className=" ml-1">*</span>
                              </label>
                              <Field
                                name="applicantdate"
                                className="  block w-full h-[calc(1.5em + 0.75rem + 2px)] py-4 px-2 text-base font-normal leading-1.5 bg-clip-padding-box border border-solid border-gray-300 rounded-sm transition duration-150 ease-in-out datepicker-Field disabled:bg-gray-300"
                                id="applicantdate"
                                type="date"
                                value={todayDate}
                                readonly
                                onChange={(value) => {
                                  setValues((val) => ({
                                    ...val,
                                    date: todayDate
                                  }))
                                }}
                                disabled={true}
                                placeholder="Select date"
                              />
                              {/* {touched.applicantdate &&
                              errors.applicantdate && (
                                <p className="text-red-700 error_msg">
                                  {errors.applicantdate}
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col  sm:flex-row mb-6 gap-4 mt-3 mb-4">
                    <div className="col-12">
                      <small>
                        By submitting this application, you agree to receive
                        marketing communication from Royal Chain Group. You
                        may opt out of receiving such communications at any
                        time.
                      </small>
                    </div>
                  </div>
                  <div classNameName="flex flex-wrap -mx-1">
                    <div className="flex  justify-start w-full max-w-full">
                      <Button
                        className="rounded-lg border  cursor-pointer px-8 h-12 leading-12 uppercase tracking-wider btn btn-primary btn-block"
                        type="submit"
                      >
                        Update
                      </Button>
                      {data.user?.kyc_status == false&&
                      <button
                        className="inline-flex  active:bg-gray-500 active:border-gray-300 justify-center items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md border bg-green-600 hover:bg-green-800  cursor-pointer px-8 h-12 leading-12 uppercase tracking-wider btn btn-primary btn-block"
                        type="button"
                        onClick={() =>{approveKyc(data.userId)}}
                      >
                        Approve KYC
                      </button>}
                    </div>
                  </div>
                </form>
          </BigSlideover>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">KYC Details</h1>
          </div>
        </div>
        <Table
          progPage={page}
          totalCount={count}
          columns={kyc_column({ onViewOpen, onDownload,approveKyc,onDelete })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <nav
          className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <select
                onChange={(e) => {
                  setLimit(e.target.value)
                  setPage(0)
                }}
                value={limit}
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div className="flex items-center md:justify-end">
            <span
              onClick={goPrev}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093] hover: duration-500 cursor-pointer"
            >
              Previous
            </span>
            <span className="text-sm ml-2">
              Page{' '}
              <input
                className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span className="ml-2"></span>/ {Math.ceil(totalPages)}
            </span>
            <span
              onClick={goNext}
              className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-[#D6A093]  hover: duration-500 cursor-pointer"
            >
              Next
            </span>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </Sidebar>
  )
}

export default KycPage
