import axios from 'axios'
import { Navigate } from 'react-router-dom'

const app_api = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL ||
    'https://api.practice-exams.nrichlearning.com.au/api/v1',
})

app_api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log(error)
      let user = JSON.parse(localStorage.getItem('moldKey'))
      app_api
        .post('/contact/logout', { email: user.email })
        .then((res) => {
          console.log(res)
          localStorage.removeItem('moldKey')
          window.location.replace(
            process.env.REACT_APP_FRONTEND_STATIC_URL ||
              'https://elearn.nrichlearning.com.au/'
          )
        })
        .catch((err) => {
          console.log(err)
        })
    } else throw error
  }
)

app_api.interceptors.request.use((config) => {
  const token = localStorage.getItem('moldKey') // Get the JWT from local storage or wherever you store it
  if (token) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(token).access_token}` // Set the Authorization header with the JWT
  }

  return config
})

export default app_api
